import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NakshatraAnalysis from "./NakshatraAnalysis";

interface CustomProps {
  data: any;
  planets: any[];
}

const Motivation = (props: CustomProps) => {
  const { t } = useTranslation();
  const [data1, setData1] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const [data3, setData3] = useState<any[]>([]);
  const [data4, setData4] = useState<any[]>([]);

  const tableHeader1 = [
    {
      name: `${t("planetsTitle")}`,
      field: "point_name",
    },
    {
      name: `${t("nakshatraTitle")}`,
      field: "nakshatra",
    },
    {
      name: `${t("motivationTitle")}`,
      field: "nakshatra_motivation",
    },
  ];

  const tableHeader2 = [
    {
      name: `${t("motivationNakshatraTitle")}`,
      field: "key",
    },
    {
      name: `${t("percentageMotivationTitle")}`,
      field: "value",
    },
  ];

  const tableHeader3 = [
    {
      name: `${t("motivationNakshatraTitle")}`,
      field: "key",
    },
    {
      name: `${t("countMotivationTitle")}`,
      field: "value",
    },
  ];

  useEffect(() => {
    convertDataToTableFormat(props.data);
  }, []);

  const convertDataToTableFormat = (data: any) => {
    const tableValue = data
      .filter((item: any) => props.planets.includes(item.point_name))
      .map((item: any) => ({
        point_name: item.point_name,
        nakshatra: `${item.nakshatra_key}.${item.name}`,
        nakshatra_motivation: capitalizeFirstLetter(item.nakshatra_motivation),
      }));
    setData1(tableValue);
    percentageCalculation(tableValue);
  };

  const percentageCalculation = (data: any) => {
    const temperamentCounts: any = {};

    data.forEach((item: any) => {
      const temperament = item.nakshatra_motivation;
      if (temperamentCounts[temperament] === undefined) {
        temperamentCounts[temperament] = 0;
      }
      temperamentCounts[temperament]++;
    });
    const totalCount = data.length;
    const percentages: any = {};

    Object.keys(temperamentCounts).forEach((temperament) => {
      percentages[temperament] =
        (temperamentCounts[temperament] / totalCount) * 100;
    });

    const temperamentPercentageData = Object.keys(percentages).map(
      (temperament) => ({
        key: temperament,
        value: `${percentages[temperament].toFixed(2)} %`,
        percentage: percentages[temperament].toFixed(2),
      })
    );

    setData2(temperamentPercentageData);
    setData3(getMaxObjects(temperamentPercentageData));

    const formattedData = [
      ["key", "value"],
      ...temperamentPercentageData.map((item) => [
        item.key,
        parseFloat(item.percentage),
      ]),
    ];
    setData4(formattedData);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getMaxObjects = (data: any[]) => {
    const max = Math.max(...data.map((item) => +item.percentage));
    return data.filter((item) => +item.percentage === max);
  };

  const getMotivation = () => {
    return (
      <NakshatraAnalysis
        chartTitle={"motivationNakshatraTitle"}
        chartData={data4}
        chartSlices={[
          { color: "#0392b2" },
          { color: "#ffa03c" },
          { color: "#9085ab" },
          { color: "#8ba64e" },
        ]}
        tableHeader1={tableHeader1}
        tableData1={data1}
        tableHeader2={tableHeader2}
        tableData2={data2}
        tableHeader3={tableHeader3}
        tableData3={data3}
      />
    );
  };

  return getMotivation();
};

export default Motivation;
