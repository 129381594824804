import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import customTabsStyles from "./CustomTabs.styles";

interface TabConfig {
  label: string;
  count?: number | null;
}

interface CustomProps {
  changeValue: Function;
  selected: string;
  tabConfig: TabConfig[];
  spotLight?: any;
  isDataChange?: boolean;
  selectedTabValue?: any;
  isTrue?: boolean;
  activeTabStyle?: any;
  inActiveTabStyle?: any;
}

const CustomTabs = (props: CustomProps) => {
  const classes = customTabsStyles;
  const [value, setValue] = useState(props.selected);

  useEffect(() => {
    setValue(props.selected);
  }, [props.selected]);

  const handleChange = (event: any, newValue: string) => {
    props.changeValue(newValue);
    setValue(props.isTrue ? props.selectedTabValue : props.selected);
  };

  const getStyle = (isActive: boolean) => {
    return isActive
      ? props.activeTabStyle
        ? { ...classes.active, ...props.activeTabStyle }
        : classes.active
      : props.inActiveTabStyle
      ? { ...classes.inActive, ...props.inActiveTabStyle }
      : classes.inActive;
  };

  return (
    <Grid container spacing={{ lg: 1, xs: 1 }} justifyContent={"center"}>
      {props.tabConfig.map((tab: TabConfig) => {
        return (
          <Grid
            item
            width={{ sm: "fit-content", xs: "100%" }}
            sx={{ cursor: "pointer" }}
          >
            <Box
              sx={classes.tabBox}
              style={getStyle(
                props.isTrue
                  ? props.selectedTabValue === tab.label
                  : value === tab.label
              )}
              onClick={(event: any) => handleChange(event, tab.label)}
            >
              <Typography sx={classes.tabText}>
                {tab.label}
                {tab.count && <Box sx={classes.counts}>{tab.count} </Box>}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CustomTabs;
