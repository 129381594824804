import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import analysisStyles from "../../Analysis.styles";

const FootballLegend = () => {
  const classes = analysisStyles;
  const { t } = useTranslation();

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} spacing={2} pt={2}>
        <Typography sx={classes.valueTypo}>{t("hitHeader1")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc1")}</Typography>
        <Typography sx={classes.valueTypo}>{t("hitHeader2")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc2")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc3")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc4")}</Typography>
        <Typography sx={classes.valueTypo}>{t("hitHeader3")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc5")}</Typography>
        <Typography sx={classes.valueTypo}>{t("hitHeader4")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc6")}</Typography>
        <Typography sx={classes.labelTypo}>{t("hitDesc7")}</Typography>
      </Stack>
    );
  };

  return <Box>{dialogBodyContent()}</Box>;
};

export default FootballLegend;
