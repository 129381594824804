import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import pricingStyles from "../Pricing.styles";
import { useTranslation } from "react-i18next";
import successImage from "../../../assets/images/successImage.png";
import { centerItemFlex, theme } from "../../../utils/styles";
import standardPlanIcon from "../../../assets/icons/standardPlanIcon.svg";
import premiumPlanIcon from "../../../assets/icons/premiumPlanIcon.svg";
import enterprisePlanIcon from "../../../assets/icons/enterprisePlanIcon.svg";
import downloadReceiptIcon from "../../../assets/icons/downloadReceiptIcon.svg";
import { CustomButton } from "../../../global/components";
import strings from "../../../global/constants/StringConstants";
import { useEffect, useState } from "react";
import { handleErrorMessage } from "../../../helpers/methods";
import { getSuccessDetails } from "../PricingService";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";
import he from "he";
import { useAppDispatch } from "../../../utils/hooks";
import { subscriptionAction } from "../../../redux/authSlice";
import AppHeader from "../../Shared/AppHeader/AppHeader";
import AppFooter from "../../Shared/AppFooter/AppFooter";

interface SuccessInvoice {
  amount: string;
  currency: string;
  endDate: string;
  invoiceNumber: string;
  planName: string;
  startDate: string;
  receiptUrl: string;
}

const Success = () => {
  const classes = pricingStyles;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState<SuccessInvoice>({
    amount: "",
    currency: "",
    endDate: "",
    invoiceNumber: "",
    planName: "",
    startDate: "",
    receiptUrl: "",
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const planImage: any = {
    Basic: standardPlanIcon,
    Premium: premiumPlanIcon,
    Enterprise: enterprisePlanIcon,
  };

  useEffect(() => {
    getSuccessInvoiceDetails(id!);
  }, []);

  const getSuccessInvoiceDetails = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await getSuccessDetails(id);
      setInvoiceDetails(response);
      dispatch(subscriptionAction({ isSubscribed: true }));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getHeader = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2}>
        <Typography sx={classes.italicTypo}>{t("confirmPayment")}</Typography>
        <Box sx={classes.normalTypo} display="inline-block">
          {t("confirmPaymentMsg1")}
          <Typography display="inline-block" sx={classes.boldTypo} pl={1}>
            {t("confirmPaymentMsg2")}
          </Typography>
        </Box>
        <Divider sx={classes.dividerStyle} />
      </Stack>
    );
  };

  const getContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={4} pt={3}>
        {isMobile ? (
          <img src={successImage} height={"150px"} />
        ) : (
          <img src={successImage} />
        )}
        {invoiceDetails.amount && (
          <Box sx={classes.invoiceDetailsMainBox}>
            <Box sx={classes.invoiceDetailsSubBox}>
              <Grid container p={2}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    borderRight: "1px solid #ffffff61",
                    ...centerItemFlex,
                    [theme.breakpoints.down("sm")]: {
                      borderRight: "none",
                      borderBottom: "1px solid #ffffff61",
                    },
                  }}
                >
                  <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography sx={classes.titleTypo}>
                        {t("invoiceNo")}
                      </Typography>
                      <Typography sx={classes.valueTypo}>:</Typography>
                      <Typography sx={classes.valueTypo}>
                        {invoiceDetails.invoiceNumber}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Typography sx={classes.titleTypo}>
                        {t("issueDate")}
                      </Typography>
                      <Typography sx={classes.valueTypo}>:</Typography>
                      <Typography sx={classes.valueTypo}>
                        {invoiceDetails.startDate}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Typography sx={classes.titleTypo}>
                        {t("dueDate")}
                      </Typography>
                      <Typography sx={classes.valueTypo}>:</Typography>
                      <Typography sx={classes.valueTypo}>
                        {invoiceDetails.endDate}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    borderRight: "1px solid #ffffff61",
                    ...centerItemFlex,
                    [theme.breakpoints.down("sm")]: {
                      borderRight: "none",
                      borderBottom: "1px solid #ffffff61",
                    },
                  }}
                >
                  <Typography sx={classes.priceText}>
                    {he.decode(invoiceDetails.amount)} {invoiceDetails.currency}
                  </Typography>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Stack direction={"column"} alignItems={"center"} spacing={1}>
                    <img
                      src={planImage[invoiceDetails.planName]}
                      height={"60px"}
                      alt="Module Not Found..."
                    />
                    <Typography sx={classes.valueTypo}>
                      {invoiceDetails.planName}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        <CustomButton
          icon={
            <img src={downloadReceiptIcon} style={{ paddingRight: "10px" }} />
          }
          label={t("receipt")}
          type={strings.SECONDARY}
          customClasses={{ maxWidth: "fit-content" }}
          onClick={() => window.open(invoiceDetails.receiptUrl)}
        />
        <Typography sx={classes.normalTypo}>{t("closeWindow")}</Typography>
      </Stack>
    );
  };

  const getPage = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="lg">
                {getHeader()}
                {getContent()}
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getSuccessPage = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box sx={{ background: "#0000004f" }}>{getPage()}</Box>
        <AppFooter />

        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getSuccessPage();
};

export default Success;
