import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { centerItemFlex } from "../../../../utils/styles";
import footballStyles from "./Football.styles";

interface CustomProps {
  percentages: { [key: string]: string };
  needColor?: boolean;
  height?: number;
  footballerChance?: any;
}

const FootballGraph: React.FC<CustomProps> = (props: CustomProps) => {
  const classes = footballStyles;
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(() =>
    Object.keys(props.percentages).map((key) => ({
      position: key,
      percentage: props.percentages[key].toString(),
    }))
  );

  useEffect(() => {
    setChartData(
      Object.keys(props.percentages).map((key) => ({
        position: key,
        percentage: props.percentages[key].toString(),
      }))
    );
  }, [props.percentages]);

  const chartRef = useRef<am4charts.XYChart3D | null>(null);

  const COLORS: { [key: string]: string } = {
    Defender: "#FFA62F",
    Forward: "#97BE5A",
    Goalkeeper: "#03A9F4",
    Midfielder: "#A997DF",
    Striker: "#028391",
  };

  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_kelly);

    const chart = am4core.create("chartdiv", am4charts.XYChart3D);
    chartRef.current = chart;

    chart.data = chartData;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "position";
    categoryAxis.title.text = "Football Positions";
    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.labels.template.fontWeight = "normal";
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("white");
    categoryAxis.title.fill = am4core.color("white");

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Prediction Percentage";
    valueAxis.renderer.labels.template.fontSize = 17;
    valueAxis.renderer.labels.template.fontWeight = "normal";
    valueAxis.renderer.labels.template.fill = am4core.color("white");
    valueAxis.title.fill = am4core.color("white");

    const series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "percentage";
    series.dataFields.categoryX = "position";
    series.name = "Percentage";
    series.tooltipText = "{name}: [bold]{valueY}%[/]";
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("black");
      series.tooltip.label.fill = am4core.color("white");
    }

    series.columns.template.adapter.add("fill", (fill: any, target: any) => {
      return props.needColor
        ? COLORS[target.dataItem!.categoryX] || fill
        : "#a5a5a5";
    });

    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{valueY}%";
    valueLabel.label.dy = -25;
    valueLabel.label.dx = 15;
    valueLabel.label.fontSize = 17;
    valueLabel.label.fontWeight = "normal";
    valueLabel.label.fill = am4core.color("white");

    chart.cursor = new am4charts.XYCursor();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [props.needColor]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = chartData;
    }
  }, [props.needColor, chartData]);

  return (
    <Box
      textAlign="center"
      width="auto"
      sx={{
        ...centerItemFlex,
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {props.footballerChance && (
        <Typography
          sx={{
            ...classes.predicationText,
            textAlign: "center",
            width: "100%",
            py: 2,
          }}
        >
          {t("footballerPotential")}: {props.footballerChance}%
        </Typography>
      )}
      <div id="chartdiv" style={{ width: "100%", minHeight: "78vh" }}></div>
    </Box>
  );
};

export default FootballGraph;
