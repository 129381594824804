import {
  boldFont,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const subscriptionStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  // mainBox: {
  //   p: 1,
  //   borderRadius: "20px",
  //   minHeight: "calc(100vh - 220px)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   background: "rgba(255, 255, 255, 0.07)",
  //   backdropFilter: "blur(12px)",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   // [theme.breakpoints.down("sm")]: {
  //   //   height: "calc(100vh - 222px)",
  //   // },
  //   // "@supports (-webkit-touch-callout: none)": {
  //   //   height: "calc(100vh - 252px)",
  //   // },
  // },
  // subBox: {
  //   p: 3,
  //   minHeight: "calc(100vh - 260px)",
  //   borderRadius: "20px",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   // display: "grid",
  //   // alignContent: "center",
  //   // overflowY: "auto",
  //   // overflowX: "hidden",
  //   // "&::-webkit-scrollbar": {
  //   //   width: "5px",
  //   // },
  //   // "&::-webkit-scrollbar-thumb": {
  //   //   borderRadius: "10px",
  //   //   border: "1px solid #FFFFFF",
  //   //   background: "#282945",
  //   // },
  //   // [theme.breakpoints.down("sm")]: {
  //   //   height: "calc(100vh - 240px)",
  //   //   p: 1,
  //   // },
  //   // "@supports (-webkit-touch-callout: none)": {
  //   //   height: "calc(100vh - 270px)",
  //   // },
  // },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    // height: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    margin: "25px 0px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
  },
  subBox: {
    p: 3,
    // height: "calc(100vh - 270px)",
    borderRadius: "20px",
    // overflowY: "auto",
    overflowX: "auto",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    border: "1px solid rgba(255, 255, 255, 0.14)",
    [theme.breakpoints.down("sm")]: {
      p: 1,
      // height: "calc(100vh - 240px)",
    },
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(15),
    textAlign: "initial",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(9),
    },
  },
  textField: {
    width: "100%",
    borderRadius: "8px",
    // border: "1px solid #FFF",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      border: "none",
      "&::placeholder": {
        ...regularFont,
        color: "#FFFFFF",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#FFFFFF",
      border: "none",
      "&.Mui-focused fieldset": {
        // border: 0,
        border: "none",
      },
    },
    "&.Mui-disabled": {
      opacity: 0.6,
      borderColor: "#000000",
    },
  },
  label: { display: "flex", height: "20px" },
  labelText: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  errorStyling: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  priceBox: {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    background: "rgba(255, 255, 255, 0.14)",
    // backdropFilter: "blur(24px)",
    boxShadow: "inset 0 1px rgba(255, 255, 255, .14)",
    px: 2,
    py: 1,
  },
  planText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    color: "#ffffff",
  },
  priceText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(20),
    color: "#ffffff",
  },
  durationText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "#ffffff66",
  },
  planDescText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#FFFFFF",
    textAlign: "center",
    px: 10,
  },
  planBtn: {
    borderRadius: "8px",
    background: "#9053F6",
    border: "1px solid #9053F6",
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",
    padding: "10px 15px",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
      minWidth: "150px",
    },
  },
  darkBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#9053F6",
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
      minWidth: "150px",
    },
  },
  modalHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(10),
    color: "#222222",
    textAlign: "center",
  },
  lightFont: {
    fontSize: getRelativeFontSize(2),
    ...regularFont,
    color: "#666666",
  },
  darkFont: {
    fontSize: getRelativeFontSize(3),
    ...mediumFont,
    color: "#222222",
  },
  cancelBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  cancelBtnBox: {
    background: "#FFFFFF",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  logoutBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color: "#FFFFFF",
      background: "#FFFFFF",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(5),
    },
  },
  cardBox: {
    borderRadius: "8px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    padding: "15px 20px",
  },
  selectedCardBox: {
    border: "3px solid #9053f6",
    cursor: "pointer",
  },
  cardText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#FFFFFF",
  },
  primaryText: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    color: "#ba91ff",
  },
  deleteText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#8876F0",
  },
  cardElementStyle: {
    borderRadius: "8px",
    border: "1px solid #000000",
    background: "rgba(255, 255, 255)",
    backdropFilter: "blur(5.5px)",
    p: 2,
  },
  labelTypo: {
    color: "#000000",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
  },
  cancelSubBtn: {
    borderRadius: "8px",
    border: "1px solid #FFFFFF",
    background: "rgba(255, 255, 255,0.0)",
    // backdropFilter: "blur(5.5px)",
    boxShadow: "inset 0 1px rgba(255, 255, 255, .05)",
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    color: "#FFFFFF",
    padding: "10px 15px",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FFFFFF",
      transform: "translateY(-0.1em)",
      background: "rgba(255, 255, 255,0.0)",
      color: "#FFFFFF",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
      minWidth: "150px",
    },
  },
  planBox: {
    background: "#9053F6",
    display: "flex",
    justifyContent: "center",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
  },
  noDataWrapper: {
    borderRadius: "15px",
    border: "3px dashed rgba(255, 255, 255, 0.21)",
    backdropFilter: "blur(24px)",
    textAlign: "center",
    py: 5,
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: getRelativeFontSize(30),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(20),
    },
  },
} as const;

export default subscriptionStyles;
