import {
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  theme,
} from "../../../utils/styles";

const customSelectStyles = {
  selectStyle: {
    width: "100%",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    // backdropFilter: "blur(5.5px)",
    boxShadow: "inset 0 50px rgba(255, 255, 255, .05)",
    // boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "1px solid #FFFFFF",
      position: "relative",
      padding: "11px 12px",
      borderRadius: "10px",
      color: "#FFFFFF",

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  nameField: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  icon: {
    fill: "#FFFFFF",
  },
  errorStyle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    marginLeft: "6px",
    color: "#d32f2f",
  },
  errorTextField: {
    "& .MuiSelect-outlined": {
      border: "1px solid #d32f2f",
      borderRadius: "10px",
      position: "relative",
      padding: "11px 12px",
      color: "#FFFFFF",
    },
  },
} as const;

export default customSelectStyles;
