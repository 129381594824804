import { Box } from "@mui/system";
import { useState } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../global/components/PrivateRoute/PrivateRoute";
import strings from "../../../global/constants/StringConstants";
import urls from "../../../global/constants/UrlConstants";
import {
  selectAuthenticated,
  selectIsSubscribed,
} from "../../../redux/authSlice";
import { doesPageHasComponent } from "../../../utils/AuthorizationManager";
import { useAppSelector } from "../../../utils/hooks";
import Analysis from "../../Analysis/Analysis";
import Calculator from "../../Calculator/Calculator";
import Dashboard from "../../Dashboard/Dashboard";
import PageNotFound from "../../PageNotFound/PageNotFound";
import Pricing from "../../Pricing/Pricing";
import Profile from "../../Profile/Profile";
import UpdatePassword from "../../ResetPassword/ResetPassword";
import Subscriptions from "../../Subscriptions/Subscriptions";
import UserProfile from "../../UserProfile/UserProfile";
import AppDrawer from "../AppDrawer/AppDrawer";
import AppFooter from "../AppFooter/AppFooter";
import AppHeader from "../AppHeader/AppHeader";
import layoutStyles from "./Layout.styles";

interface CustomProps {
  location?: Location;
}

const Layout = (props: CustomProps) => {
  const classes = layoutStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Switch>
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.DASHBOARD}
            path={urls.dashboardViewPath}
            component={Dashboard}
            isSubscribed={true}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.PRICING}
            path={[urls.pricingViewPath, urls.paymentViewPath]}
            component={Pricing}
            isSubscribed={true}
          />
          {/* <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.SUCCESS}
            path={urls.successViewPath}
            component={Success}
            isSubscribed={true}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.FAIL}
            path={urls.failViewPath}
            component={Fail}
            isSubscribed={true}
          /> */}
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.USER_PROFILE}
            path={urls.userProfileViewPath}
            component={UserProfile}
            isSubscribed={true}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.SUBSCRIPTION}
            path={urls.subscriptionViewPath}
            component={Subscriptions}
            isSubscribed={true}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.UPDATE_PASSWORD}
            path={urls.updatePasswordViewPath}
            component={UpdatePassword}
            isSubscribed={true}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.PROFILE}
            path={urls.profileViewPath}
            component={Profile}
            isSubscribed={isSubscribed}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.CALCULATOR}
            path={urls.calculateViewPath}
            component={Calculator}
            isSubscribed={isSubscribed}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            componentName={strings.ANALYSIS}
            path={[
              urls.analysisViewPath,
              urls.missingMineralsViewPath,
              urls.mahadashaViewPath,
              urls.planetDetailsViewPath,
              urls.footballPredictionViewPath,
              urls.nakshatraAnalysisViewPath,
              urls.circularChartViewPath,
            ]}
            component={Analysis}
            isSubscribed={isSubscribed}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={""}
            component={PageNotFound}
            componentName={strings.PAGENOTFOUND}
          />
        </Switch>
      </Box>
    );
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getLayout = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box sx={classes.root}>
          {doesPageHasComponent("appdrawer") && (
            <AppDrawer setMenuMobileVisible={toggleDrawer} />
          )}

          {getContent()}
        </Box>
        <AppFooter />
      </>
    );
  };

  return getLayout();
};

export default Layout;
