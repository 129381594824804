import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getAnalysisData = async (profileId: string, language: string) => {
  const url = `${urls.analysisData}/${profileId}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMineralsData = async (profileId: string, language: string) => {
  const url = `${urls.missingMinerals}/${profileId}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMahadashaData = async (profileId: string, language: string) => {
  const url = `${urls.mahadashaData}/${profileId}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getInnerDashaData = async (innerDashaObject: any) => {
  try {
    const callParams = await getCallParams("POST", innerDashaObject);
    const response = await makeCall(urls.innerDashaCosmicData, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMaleficBeneficDashaData = async (
  profileId: string,
  malefic: boolean
) => {
  const url = `${
    malefic ? urls.maleficDashaData : urls.beneficDashaData
  }/${profileId}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getBeneficDashaData = async (profileId: string) => {
  const url = `${urls.beneficDashaData}/${profileId}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getBuyNowUrl = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.buyNowUrl, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getPlanetData = async (profileId: string, language: string) => {
  const url = `${urls.planetData}/${profileId}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function getCircularChart(profileId: string, language: string) {
  const url = `${urls.circularChart}/${profileId}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
