import amexIcon from "../assets/icons/CardIcon/amex.png";
import defaultIcon from "../assets/icons/CardIcon/default.png";
import dinersIcon from "../assets/icons/CardIcon/diners.png";
import discoverIcon from "../assets/icons/CardIcon/discover.png";
import jcbIcon from "../assets/icons/CardIcon/jcb.png";
import mastercardIcon from "../assets/icons/CardIcon/mastercard.png";
import visaIcon from "../assets/icons/CardIcon/visa.png";
import Phase1 from "../assets/icons/MoonPhases/1.svg";
import Phase10 from "../assets/icons/MoonPhases/10.svg";
import Phase11 from "../assets/icons/MoonPhases/11.svg";
import Phase12 from "../assets/icons/MoonPhases/12.svg";
import Phase13 from "../assets/icons/MoonPhases/13.svg";
import Phase14 from "../assets/icons/MoonPhases/14.svg";
import Phase15 from "../assets/icons/MoonPhases/15.svg";
import Phase16 from "../assets/icons/MoonPhases/16.svg";
import Phase17 from "../assets/icons/MoonPhases/17.svg";
import Phase18 from "../assets/icons/MoonPhases/18.svg";
import Phase19 from "../assets/icons/MoonPhases/19.svg";
import Phase2 from "../assets/icons/MoonPhases/2.svg";
import Phase20 from "../assets/icons/MoonPhases/20.svg";
import Phase21 from "../assets/icons/MoonPhases/21.svg";
import Phase22 from "../assets/icons/MoonPhases/22.svg";
import Phase23 from "../assets/icons/MoonPhases/23.svg";
import Phase24 from "../assets/icons/MoonPhases/24.svg";
import Phase25 from "../assets/icons/MoonPhases/25.svg";
import Phase26 from "../assets/icons/MoonPhases/26.svg";
import Phase27 from "../assets/icons/MoonPhases/27.svg";
import Phase28 from "../assets/icons/MoonPhases/28.svg";
import Phase29 from "../assets/icons/MoonPhases/29.svg";
import Phase3 from "../assets/icons/MoonPhases/3.svg";
import Phase30 from "../assets/icons/MoonPhases/30.svg";
import Phase4 from "../assets/icons/MoonPhases/4.svg";
import Phase5 from "../assets/icons/MoonPhases/5.svg";
import Phase6 from "../assets/icons/MoonPhases/6.svg";
import Phase7 from "../assets/icons/MoonPhases/7.svg";
import Phase8 from "../assets/icons/MoonPhases/8.svg";
import Phase9 from "../assets/icons/MoonPhases/9.svg";
import strings from "../global/constants/StringConstants";
import urls from "../global/constants/UrlConstants";
import { logOutAction } from "../redux/authSlice";
import { globalEmitter } from "../utils/emitter";
import history from "../utils/history";
import { store } from "../utils/store";

const methodsContext = this;

export const isTruthy = (value: any, shouldCheckByType: boolean = true) => {
  const validatedByType = shouldCheckByType
    ? customValidatorByType(value)
    : true;

  if (value !== null && value !== undefined && validatedByType) {
    return true;
  }
  return false;
};

const customValidatorByType = (value: any) => {
  if (value !== undefined && value !== null) {
    const type = typeof value;
    switch (type) {
      case "string":
        return value.trim() !== "";
      case "object":
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      default:
        return true;
    }
  }
};

export const compareStrings = (string1: string, string2: string) => {
  if (!(isTruthy(string1) || isTruthy(string2))) {
    return true;
  } else {
    if (string1 && string2) {
      if (string1.toLowerCase() === string2.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const openInfoNotification = (message: any, title: string = "Info") => {
  globalEmitter.emit(strings.notification, {
    type: strings.info,
    message: message,
    title: title,
  });
};

export const openSuccessNotification = (
  message: any,
  title: string = "Success"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.success,
    message: message,
    title: title,
  });
};

export const openWarningNotification = (
  message: any,
  title: string = "Warning"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.warning,
    message: message,
    title: title,
  });
};

export const openErrorNotification = (
  message: any,
  title: string = "Error"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.error,
    message: message,
    title: title,
  });
};

export const handleErrorMessage = (error: any) => {
  if (error.errorCode) {
    switch (error.errorCode) {
      // case 400:
      //   return openErrorNotification("Please check your request and try again");
      case 401:
        store.dispatch(logOutAction());
        history.push(urls.loginViewPath);
        return null;
      // case 403:
      //   return openErrorNotification(
      //     "You are not authorized to access this resource."
      //   );
      // case 404:
      //   return openErrorNotification(
      //     "The requested resource could not be found"
      //   );
      // case 500:
      //   return openErrorNotification(
      //     "There was an error on the server side.Please try again later."
      //   );
      // case 406:
      //   return openErrorNotification(error.message);
      default:
        return openErrorNotification(error.errorMessage);
    }
  } else if (
    error.message === "Failed to fetch" &&
    error.name === "TypeError"
  ) {
    return openErrorNotification(
      "This is a critical error that requires immediate attention from the support team. Please contact them."
    );
  }
};

export const convertPriceToDollarFormat = (value: number) => {
  return `$${(value / 100).toFixed(2)}`;
};

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (...args: any) {
    const context = methodsContext;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceEventHandler(func: Function, wait: number) {
  const debounced = debounce(func, wait);
  return function (event: any) {
    event.persist();
    return debounced(event);
  };
}

export const getFormattedStatsCount = (value: number) => {
  // for regex
  // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return new Intl.NumberFormat("en-US").format(value);
};

export const getFormattedNumbers = (value: string) => {
  const matches = value.match(/\d+/g);
  let number = "";
  if (matches !== null) {
    matches.forEach((match) => {
      number = number + match;
    });
  }
  if (number.length === 10) {
    value = number.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return { number: number, maskedNumber: value };
};

export const getMoonPhasesSymbols = (phase: number) => {
  switch (phase) {
    case 1: {
      return Phase1;
    }
    case 2: {
      return Phase2;
    }
    case 3: {
      return Phase3;
    }
    case 4: {
      return Phase4;
    }
    case 5: {
      return Phase5;
    }
    case 6: {
      return Phase6;
    }
    case 7: {
      return Phase7;
    }
    case 8: {
      return Phase8;
    }
    case 9: {
      return Phase9;
    }
    case 10: {
      return Phase10;
    }
    case 11: {
      return Phase11;
    }
    case 12: {
      return Phase12;
    }
    case 13: {
      return Phase13;
    }
    case 14: {
      return Phase14;
    }
    case 15: {
      return Phase15;
    }
    case 16: {
      return Phase16;
    }
    case 17: {
      return Phase17;
    }
    case 18: {
      return Phase18;
    }
    case 19: {
      return Phase19;
    }
    case 20: {
      return Phase20;
    }
    case 21: {
      return Phase21;
    }
    case 22: {
      return Phase22;
    }
    case 23: {
      return Phase23;
    }
    case 24: {
      return Phase24;
    }
    case 25: {
      return Phase25;
    }
    case 26: {
      return Phase26;
    }
    case 27: {
      return Phase27;
    }
    case 28: {
      return Phase28;
    }
    case 29: {
      return Phase29;
    }
    case 30: {
      return Phase30;
    }
    default: {
      return Phase15;
    }
  }
};

export const getCardSymbols = (brand: string) => {
  switch (brand) {
    case "visa": {
      return visaIcon;
    }
    case "amex": {
      return amexIcon;
    }
    case "diners": {
      return dinersIcon;
    }
    case "discover": {
      return discoverIcon;
    }
    case "jcb": {
      return jcbIcon;
    }
    case "mastercard": {
      return mastercardIcon;
    }
    default: {
      return defaultIcon;
    }
  }
};

export const capitalizeWords = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());
