import { PhoneNumberUtil } from "google-libphonenumber";
import { LoginFields } from "../../models/interfaces";

export const loginForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
    forgotEmail: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const loginValidation = (loginUserData: any) => {
  let errors = loginUserData;
  let isValid = true;
  const email = loginUserData.email.value;
  const password = loginUserData.password.value;
  if (!email) {
    errors.email.error = "Please enter your email id";
    isValid = false;
  }
  if (!password) {
    errors.password.error = "Please enter your password";
    isValid = false;
  }
  return { isValid, errors };
};

export const registerFormField = () => {
  return {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    contact: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

const isPhoneValid = (phone: string) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error: any) {
    return false;
  }
};

export const registrationValidation = (
  registerUserValue: any,
  address: any
) => {
  let errors = registerUserValue;
  let isValid = true;
  const firstName = registerUserValue.firstName.value;
  const lastName = registerUserValue.lastName.value;
  const email = registerUserValue.email.value;
  const location = address.location;
  const latitude = address.latitude;
  const longitude = address.longitude;
  const contact = registerUserValue.contact.value;

  if (!firstName) {
    errors.firstName.error = "Please enter first name";
    isValid = false;
  }
  if (!lastName) {
    errors.lastName.error = "Please enter last name";
    isValid = false;
  }
  if (!email) {
    errors.email.error = "Please enter email";
    isValid = false;
  }
  if (!location) {
    errors.location.error = "Please select a valid location";
    isValid = false;
  }
  if (!latitude) {
    errors.location.error = "Please select a valid location";
    isValid = false;
  }
  if (!longitude) {
    errors.location.error = "Please select a valid location";
    isValid = false;
  }
  if (contact.length > 6 && !isPhoneValid(contact)) {
    errors.contact.error = "Please enter valid contact";
    isValid = false;
  }
  return { isValid, errors };
};

export const resetPasswordForm = () => {
  return {
    confirmPassword: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    oldPassword: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const resetPasswordValidation = (
  resetPasswordUserData: any,
  oldPasswordRequired: boolean
) => {
  let errors = resetPasswordUserData;
  let isValid = true;
  const oldPassword = resetPasswordUserData.oldPassword.value;
  const password = resetPasswordUserData.password.value;
  const confirmPassword = resetPasswordUserData.confirmPassword.value;
  if (oldPasswordRequired && !oldPassword) {
    errors.oldPassword.error = "Please enter your old password";
    isValid = false;
  }
  if (!password) {
    errors.password.error = "Please enter your password";
    isValid = false;
  }
  if (!confirmPassword) {
    errors.confirmPassword.error = "Please enter your confirm password";
    isValid = false;
  }
  return { isValid, errors };
};
