import {
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  theme,
} from "../../../utils/styles";

const customContactNumberStyles = {
  textField: {
    // width: "100%",
    // borderRadius: "12px",
    // "& .MuiInputBase-input": {
    //   position: "relative",
    //   padding: "12px 12px",
    //   backgroundColor: "#fff",
    //   "&::placeholder": {
    //     ...mediumFont,
    //   },
    // },
    // "& .MuiOutlinedInput-root": {
    //   borderRadius: "10px",
    //   "&.Mui-focused fieldset": {
    //     borderColor: "#4B0150",
    //   },
    // },
    width: "100%",
    borderRadius: "12px",
    ".MuiOutlinedInput-notchedOutline": { border: "1px solid #FFFFFF" },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF",
    },
    "& .MuiInputBase-input": {
      // border: "1px solid #FFFFFF",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "10px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      background: "rgba(255, 255, 255, 0.05)",
      // backdropFilter: "blur(5.5px)",
      boxShadow: "inset 0 50px rgba(255, 255, 255, .05)",
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        border: "1px solid #FFFFFF",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: getRelativeFontSize(1),
      ...mediumFont,
    },
  },
  nameField: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
} as const;

export default customContactNumberStyles;
