import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../../helpers/methods";
import { theme } from "../../../../utils/styles";
import analysisStyles from "../../Analysis.styles";
import { getCircularChart } from "../../AnalysisService";
interface CustomProps {
  profileId: string;
  setIsLoading: Function;
}

const CircularBirthChart = (props: CustomProps) => {
  const classes = analysisStyles;
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { i18n, t } = useTranslation();
  const [circularChartImage, setCircularChartImage] = useState({
    desktop: "",
    mobile: "",
  });

  useEffect(() => {
    handleCircularChart();
  }, [i18n.language]);

  const handleCircularChart = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getCircularChart(props.profileId, language);
      setCircularChartImage({
        desktop: "data:image/svg+xml;base64," + response.circularChart,
        mobile: "data:image/svg+xml;base64," + response.circularChartMobile,
      });
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getLegend = () => {
    return (
      <Stack direction={"column"} p={2} spacing={2}>
        <Typography sx={classes.greenText}>
          <b style={classes.greenLargeText}>{t("green")}:</b> {t("greenDesc1")}{" "}
          <b style={classes.greenLargeText}>Ketu</b>, {t("greenDesc2")}{" "}
          <b style={classes.greenLargeText}>{t("greenDesc3")}</b>,{" "}
          {t("greenDesc4")}
        </Typography>
        <Typography sx={classes.redText}>
          <b style={classes.greenLargeText}>{t("red")}:</b> {t("redDesc1")}{" "}
          <b style={classes.greenLargeText}>Rahu</b>, {t("redDesc2")}
        </Typography>
      </Stack>
    );
  };

  const getCircularBirthChart = () => {
    return (
      <Box p={2} sx={{ position: "relative", overflow: "auto" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          component="a"
          href={
            isTablet ? circularChartImage.mobile : circularChartImage.desktop
          }
          download="circular-birth-chart.svg"
        >
          <DownloadIcon htmlColor="#6B72FF" fontSize="large" />
        </IconButton>
        <img
          src={
            isTablet ? circularChartImage.mobile : circularChartImage.desktop
          }
          style={{ minWidth: "500px" }}
          alt="Chart Not Found..."
        />
      </Box>
    );
  };

  return (
    <>
      {getLegend()}
      {getCircularBirthChart()}
    </>
  );
};

export default CircularBirthChart;
