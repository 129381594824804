import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NakshatraAnalysis from "./NakshatraAnalysis";

interface CustomProps {
  data: any;
  planets: any[];
}

const DoshaPada = (props: CustomProps) => {
  const { t } = useTranslation();
  const [data1, setData1] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const [data3, setData3] = useState<any[]>([]);
  const [data4, setData4] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([
    {
      name: `${t("planetsTitle")}`,
      field: "point_name",
    },
    {
      name: `${t("nakshatraTitle")}`,
      field: "nakshatra",
    },
    {
      name: `${t("nakshatraDoshaTitle")}`,
      field: "nakshatra_tridosha",
    },
    {
      name: `${t("padaTitle")}`,
      field: "pada",
    },
    {
      name: `${t("padaSignTitle")}`,
      field: "pada_sign",
    },
    {
      name: `${t("padaElementTitle")}`,
      field: "pada_dosha",
    },
  ]);

  const tableHeader2 = [
    {
      name: `${t("doshaTemperamentPadaTitle")}`,
      field: "key",
    },
    {
      name: `${t("percentageDoshaTemperamentPadaTitle")}`,
      field: "value",
    },
  ];

  const tableHeader3 = [
    {
      name: `${t("doshaTemperamentPadaTitle")}`,
      field: "key",
    },
    {
      name: `${t("countDoshaTemperamentPadaTitle")}`,
      field: "value",
    },
  ];

  useEffect(() => {
    convertDataToTableFormat(props.data);
  }, []);

  interface TemperamentValues {
    [key: string]: number;
  }

  const calculateValues = (
    temperamentNames: string[],
    nakshatraTemperament: string,
    padaTemperament: string
  ): TemperamentValues => {
    const values: TemperamentValues = {};

    temperamentNames.forEach((temperament) => {
      values[temperament.toLowerCase()] = 0;
    });

    if (
      temperamentNames.every((t) =>
        nakshatraTemperament.toLowerCase().includes(t)
      )
    ) {
      temperamentNames.forEach((temperament) => {
        values[temperament.toLowerCase()] = 100;
      });
    } else {
      temperamentNames.forEach((temperament) => {
        if (
          nakshatraTemperament.toLowerCase().includes(temperament.toLowerCase())
        ) {
          values[temperament.toLowerCase()] += 75;
        }
        if (padaTemperament.toLowerCase().includes(temperament.toLowerCase())) {
          values[temperament.toLowerCase()] += 25;
        }
      });
    }

    return values;
  };

  const convertDataToTableFormat = (data: any) => {
    const temperamentNames: string[] = Array.from(
      new Set(
        data.flatMap(
          (item: { nakshatra_tridosha: string; pada_dosha: string }) => [
            capitalizeFirstLetter(item.nakshatra_tridosha),
            capitalizeFirstLetter(item.pada_dosha),
          ]
        )
      )
    );

    const dynamicColumns = temperamentNames.map((temperamentName: string) => ({
      name: temperamentName,
      field: temperamentName.toLowerCase(),
    }));

    setHeader([...header, ...dynamicColumns]);

    const tableValue = data
      .filter((item: any) => props.planets.includes(item.point_name))
      .map((item: any) => {
        const temperamentValues: TemperamentValues = calculateValues(
          temperamentNames,
          item.nakshatra_tridosha,
          item.pada_dosha
        );

        Object.keys(temperamentValues).forEach((key) => {
          if (temperamentValues[key] === 0) {
            delete temperamentValues[key];
          }
        });

        return {
          point_name: item.point_name,
          nakshatra: `${item.nakshatra_key}.${item.name}`,
          nakshatra_tridosha: capitalizeFirstLetter(item.nakshatra_tridosha),
          pada: item.pada,
          pada_sign: item.pada_sign,
          pada_dosha: capitalizeFirstLetter(item.pada_dosha),
          ...temperamentValues,
        };
      });

    setData1(tableValue);
    percentageCalculation(tableValue, temperamentNames);
  };

  const percentageCalculation = (data: any, temperamentNames: string[]) => {
    const temperamentCounts: any = {};

    data.forEach((item: any) => {
      temperamentNames.forEach((temperament) => {
        if (temperamentCounts[temperament] === undefined) {
          temperamentCounts[temperament] = 0;
        }
        if (item[temperament.toLowerCase()] !== undefined) {
          temperamentCounts[temperament] += item[temperament.toLowerCase()];
        }
      });
    });

    const totalCount = data.length * 100;

    const percentages: any = {};

    Object.keys(temperamentCounts).forEach((temperament) => {
      if (temperamentCounts[temperament] !== 0) {
        percentages[temperament] =
          (temperamentCounts[temperament] / totalCount) * 100;
      }
    });

    const temperamentPercentageData = Object.keys(percentages).map(
      (temperament) => ({
        key: temperament,
        value: `${percentages[temperament].toFixed(2)} %`,
        percentage: percentages[temperament].toFixed(2),
      })
    );

    setData2(temperamentPercentageData);
    setData3(getMaxObjects(temperamentPercentageData));

    const formattedData = [
      ["key", "value"],
      ...temperamentPercentageData.map((item) => [
        item.key,
        parseFloat(item.percentage),
      ]),
    ];
    setData4(formattedData);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getMaxObjects = (data: any[]) => {
    const max = Math.max(...data.map((item) => +item.percentage));
    return data.filter((item) => +item.percentage === max);
  };

  const getDoshaPada = () => {
    return (
      <NakshatraAnalysis
        chartTitle={"doshaTemperamentPadaTitle"}
        chartData={data4}
        chartSlices={[
          { color: "#f99203" },
          { color: "#32e5e6" },
          { color: "#867ef1" },
        ]}
        tableHeader1={header}
        tableData1={data1}
        tableHeader2={tableHeader2}
        tableData2={data2}
        tableHeader3={tableHeader3}
        tableData3={data3}
      />
    );
  };

  return getDoshaPada();
};

export default DoshaPada;
