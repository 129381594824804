import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import backArrow from "../../assets/icons/backArrow.svg";
import whiteDeleteIcon from "../../assets/icons/whiteDeleteIcon.svg";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "../../global/components";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import { setProfileIdAction } from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppDispatch } from "../../utils/hooks";
import { theme } from "../../utils/styles";
import {
  days,
  formData,
  hours,
  minutesSeconds,
  years,
} from "../Calculator/CalculatorData";
import profileStyles from "./Profile.styles";
import { deleteProfile, getProfileById, updateProfile } from "./ProfileService";

const Profile = () => {
  const classes = profileStyles;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formFields, setFormFields] = useState(formData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        birthPlace: {
          ...formFields.birthPlace,
          value: address.location,
        },
      });
    !formFields.birthPlace.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  useEffect(() => {
    getProfileDetails();
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  const formValidation = (formData: any) => {
    let errors = formData;
    let isValid = true;
    const firstName = formData.firstName.value;
    const year = formData.year.value;
    const month = formData.month.value;
    const date = formData.date.value;
    const hours = formData.hours.value;
    const minutes = formData.minutes.value;
    const seconds = formData.seconds.value;
    const birthPlace = formData.birthPlace.value;
    const latitude = address.latitude;
    const longitude = address.longitude;
    if (!firstName) {
      errors.firstName.error = t("firstNameErrorMessage");
      isValid = false;
    }
    if (year === "0") {
      errors.year.error = t("yearErrorMessage");
      isValid = false;
    }
    if (month === "0") {
      errors.month.error = t("monthErrorMessage");
      isValid = false;
    }
    if (date === "0") {
      errors.date.error = t("dateErrorMessage");
      isValid = false;
    }
    if (hours === "-1") {
      errors.hours.error = t("hourErrorMessage");
      isValid = false;
    }
    if (minutes === "-1") {
      errors.minutes.error = t("minuteErrorMessage");
      isValid = false;
    }
    if (seconds === "-1") {
      errors.seconds.error = t("secondErrorMessage");
      isValid = false;
    }
    if (!birthPlace) {
      errors.birthPlace.error = t("birthPlaceErrorMessage");
      isValid = false;
    }
    if (!latitude) {
      errors.birthPlace.error = t("latLongErrorMessage");
      isValid = false;
    }
    if (!longitude) {
      errors.birthPlace.error = t("latLongErrorMessage");
      isValid = false;
    }
    return { isValid, errors };
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getProfileById(id!);
      setAddress({
        ...address,
        latitude: response.latitude,
        longitude: response.longitude,
        location: "",
      });
      setFormFields({
        firstName: {
          value: response.firstName,
          error: "",
        },
        lastName: {
          value: response.lastName,
          error: "",
        },
        bloodGroup: {
          value: response.bloodGroup,
          error: "",
        },
        sex: {
          value: response.sex,
          error: "",
        },
        year: {
          value: response.year,
          error: "",
        },
        month: {
          value: response.month,
          error: "",
        },
        date: {
          value: response.date,
          error: "",
        },
        hours: {
          value: response.hours,
          error: "",
        },
        minutes: {
          value: response.minutes,
          error: "",
        },
        seconds: {
          value: response.seconds,
          error: "",
        },
        birthDate: {
          value: response.birthDate,
          error: "",
        },
        birthTime: {
          value: response.birthTime,
          error: "",
        },
        birthPlace: {
          value: response.birthPlace,
          error: "",
        },
        pregnancy: {
          value: response.pregnancy,
          error: "",
        },
        longitude: {
          value: response.longitude,
          error: "",
        },
        latitude: {
          value: response.latitude,
          error: "",
        },
        timezone: {
          value: response.timezone,
          error: "",
        },
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const bloodGroupMenu = [
    { value: 0, content: `${t("optional")}`, disabled: true },
    { value: "A+", content: "A+" },
    { value: "A-", content: "A-" },
    { value: "B+", content: "B+" },
    { value: "B-", content: "B-" },
    { value: "O+", content: "O+" },
    { value: "O-", content: "O-" },
    { value: "AB+", content: "AB+" },
    { value: "AB-", content: "AB-" },
  ];

  const genderMenu = [
    { value: "Man", content: `${t("man")}` },
    { value: "Woman", content: `${t("woman")}` },
  ];

  const yearMenu = [
    { value: 0, content: `${t("year")}`, disabled: true },
    ...years,
  ];

  const monthMenu = [
    { value: 0, content: `${t("month")}`, disabled: true },
    { content: `${t("january")}`, value: 1 },
    { content: `${t("february")}`, value: 2 },
    { content: `${t("march")}`, value: 3 },
    { content: `${t("april")}`, value: 4 },
    { content: `${t("may")}`, value: 5 },
    { content: `${t("june")}`, value: 6 },
    { content: `${t("july")}`, value: 7 },
    { content: `${t("august")}`, value: 8 },
    { content: `${t("september")}`, value: 9 },
    { content: `${t("october")}`, value: 10 },
    { content: `${t("november")}`, value: 11 },
    { content: `${t("december")}`, value: 12 },
  ];

  const dateMenu = [
    { value: 0, content: `${t("date")}`, disabled: true },
    ...days,
  ];

  const hoursMenu = [
    { value: -1, content: `${t("hour")}`, disabled: true },
    ...hours,
  ];

  const minutesMenu = [
    { value: -1, content: `${t("minute")}`, disabled: true },
    ...minutesSeconds,
  ];

  const secondsMenu = [
    { value: -1, content: `${t("second")}`, disabled: true },
    ...minutesSeconds,
  ];

  const pregnancyMenu = [
    { value: -1, content: `${t("month")}`, disabled: true },
    { value: 9, content: `9 ${t("months")}` },
    { value: 8, content: `8 ${t("months")}` },
    { value: 7, content: `7 ${t("months")}` },
    { value: 6, content: `6 ${t("months")}` },
  ];

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  function initialize() {
    const input: any = document.getElementById("birthPlace");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  }

  const handleUpdateProfile = async () => {
    try {
      if (handleValidation()) {
        const profileData = {
          firstName: formFields.firstName.value.trim(),
          lastName: formFields.lastName.value.trim(),
          bloodGroup: formFields.bloodGroup.value,
          sex: formFields.sex.value,
          profileId: id,
          birthDate:
            `${formFields.year.value}-${formFields.month.value}-${formFields.date.value}` +
            " " +
            `${formFields.hours.value}:${formFields.minutes.value}:${formFields.seconds.value}`,
          longitude: address.longitude,
          latitude: address.latitude,
          altitude: "0",
          timezone: formFields.timezone.value,
          pregnancy: +formFields.pregnancy.value,
          birthPlace: formFields.birthPlace.value,
        };
        setIsLoading(true);
        const response = await updateProfile(profileData);
        openSuccessNotification(response.message);
        handleBackClick(id!);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleFieldChange = (event: any) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
    if (event.target.name === "birthPlace") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const handleBackClick = (profileId: string) => {
    history.push(urls.analysisViewPath);
    dispatch(
      setProfileIdAction({
        profileId: profileId,
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteProfile = async () => {
    try {
      setIsLoading(true);
      const response = await deleteProfile(id!);
      history.push(urls.homeViewPath);
      setIsLoading(false);
      openSuccessNotification(response.message);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getForm = () => {
    return (
      <Grid container spacing={1} justifyContent={"center"}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { sm: "center", xs: "baseline" },
              width: "100%",
            }}
            pb={3}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer", width: "fit-content" }}
              onClick={() => history.goBack()}
            >
              {isMobile ? (
                <img
                  src={backArrow}
                  height={"20px"}
                  alt="Module not found..."
                />
              ) : (
                <img
                  src={backArrow}
                  height={"25px"}
                  alt="Module not found..."
                />
              )}
            </Box>
            <Typography sx={classes.italicTypo}>{t("personalData")}</Typography>
            <IconButton onClick={() => setOpen(true)}>
              <img src={whiteDeleteIcon} height={"45px"} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomInput
            required
            label={t("firstName")}
            name="firstName"
            value={formFields.firstName.value}
            onChange={handleFieldChange}
            placeHolder={t("firstNamePlaceholder")}
            autoComplete="off"
            error={
              !isTruthy(formFields.firstName.value) &&
              formFields.firstName.error
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomInput
            label={t("lastName")}
            name="lastName"
            value={formFields.lastName.value}
            onChange={handleFieldChange}
            placeHolder={t("lastNamePlaceholder")}
            autoComplete="off"
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            label={t("bloodGroup")}
            menuItems={bloodGroupMenu}
            onChange={handleFieldChange}
            id={"bloodGroup"}
            name={"bloodGroup"}
            value={formFields.bloodGroup.value}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("sex")}
            menuItems={genderMenu}
            onChange={handleFieldChange}
            id={"sex"}
            name={"sex"}
            value={formFields.sex.value}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
            <InputLabel required sx={classes.nameField}>
              {t("birthDate")}
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={yearMenu}
            onChange={handleFieldChange}
            id={"year"}
            name={"year"}
            value={formFields.year.value}
            error={formFields.year.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={monthMenu}
            onChange={handleFieldChange}
            id={"month"}
            name={"month"}
            value={formFields.month.value}
            error={formFields.month.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={dateMenu}
            onChange={handleFieldChange}
            id={"date"}
            name={"date"}
            value={formFields.date.value}
            error={formFields.date.error}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
            <InputLabel required sx={classes.nameField}>
              {t("birthTime")}
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={hoursMenu}
            onChange={handleFieldChange}
            id={"hours"}
            name={"hours"}
            value={formFields.hours.value}
            error={formFields.hours.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={minutesMenu}
            onChange={handleFieldChange}
            id={"minutes"}
            name={"minutes"}
            value={formFields.minutes.value}
            error={formFields.minutes.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={secondsMenu}
            onChange={handleFieldChange}
            id={"seconds"}
            name={"seconds"}
            value={formFields.seconds.value}
            error={formFields.seconds.error}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
          <CustomInput
            required
            label={t("birthPlace")}
            placeHolder={t("place")}
            id="birthPlace"
            type="birthPlace"
            name="birthPlace"
            value={formFields.birthPlace.value}
            onChange={handleFieldChange}
            error={
              !isTruthy(
                formFields.birthPlace.value &&
                  address.latitude &&
                  address.longitude
              ) && formFields.birthPlace.error
            }
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
          <CustomSelect
            label={t("pregnancy")}
            menuItems={pregnancyMenu}
            onChange={handleFieldChange}
            id={"pregnancy"}
            name={"pregnancy"}
            value={formFields.pregnancy.value}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} mt={3}>
          <CustomButton
            label={t("save")}
            type={strings.PRIMARY}
            onClick={handleUpdateProfile}
          />
        </Grid>
      </Grid>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {t("deleteProfileHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} pb={5} spacing={2} sx={classes.dialogContent}>
        <Typography sx={classes.lightFont} pt={2}>
          {t("deleteProfileContent")}
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={2}
          alignSelf={"center"}
        >
          <Button onClick={handleClose} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText"> {t("no")}</Typography>
            </Box>
          </Button>
          <Button onClick={handleDeleteProfile} sx={classes.logoutBtn}>
            <Typography className="btnText"> {t("yes")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };

  const getDeleteDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  const getProfile = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="lg" sx={{ px: 1 }}>
                {getForm()}
              </Container>
            </Box>
          </Box>
        </Box>
        {getDeleteDialog()}
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };
  return getProfile();
};

export default Profile;
