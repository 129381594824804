import { Box } from "@mui/material";
import { Cell, BarChart, XAxis, YAxis, Tooltip, Bar } from "recharts";
import { boldFont, centerItemFlex } from "../../../../utils/styles";

interface CustomProps {
  data: any[];
}

const MahadashaChartComponent = (props: CustomProps) => {
  const COLORS: any = {
    Malefic: "red",
    Benefic: "#22cb22",
    Neutral: "white",
  };

  return (
    <Box textAlign="center" width="auto" sx={{ ...centerItemFlex }}>
      <BarChart
        width={320}
        height={150}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="color" tick={{ fill: "white" }} />
        <YAxis tick={{ fill: "white" }} />
        <Tooltip
          labelStyle={{ display: "none" }}
          itemStyle={{ fontSize: "12px" }}
          contentStyle={{ background: "white" }}
          content={({ payload }: any) => {
            const data = payload && payload[0] && payload[0].payload;
            return data ? (
              <div>
                <p
                  style={{ color: "#686cf5", ...boldFont, fontSize: "20px" }}
                >{`${data.percentage.toFixed(0)}%`}</p>
              </div>
            ) : null;
          }}
        />
        <Bar dataKey="percentage">
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.color ?? ""]} />
          ))}
        </Bar>
      </BarChart>
    </Box>
  );
};

export default MahadashaChartComponent;
