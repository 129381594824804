import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NakshatraAnalysis from "./NakshatraAnalysis";

interface CustomProps {
  data: any;
  planets: any[];
}

const Gunas = (props: CustomProps) => {
  const { t } = useTranslation();
  const [data1, setData1] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const [data3, setData3] = useState<any[]>([]);
  const [data4, setData4] = useState<any[]>([]);

  const tableHeader1 = [
    {
      name: `${t("planetsTitle")}`,
      field: "point_name",
    },
    {
      name: `${t("nakshatraTitle")}`,
      field: "nakshatra",
    },
    {
      name: `${t("rajasTitle")}`,
      field: "rajas",
    },
    {
      name: `${t("tamasTitle")}`,
      field: "tamas",
    },
    {
      name: `${t("sattwaTitle")}`,
      field: "sattwa",
    },
  ];

  const tableHeader2 = [
    {
      name: `${t("gunasNakshatraTitle")}`,
      field: "key",
    },
    {
      name: `${t("totalGunasNakshatraTitle")}`,
      field: "total",
    },
    {
      name: `${t("percentagegunasTitle")}`,
      field: "value",
    },
  ];

  const tableHeader3 = [
    {
      name: `${t("gunasNakshatraTitle")}`,
      field: "key",
    },
    {
      name: `${t("countGunasTitle")}`,
      field: "value",
    },
  ];

  useEffect(() => {
    convertDataToTableFormat(props.data);
  }, []);

  const convertDataToTableFormat = (data: any) => {
    const tableValue = data
      .filter((item: any) => props.planets.includes(item.point_name))
      .map((item: any) => ({
        point_name: item.point_name,
        nakshatra: `${item.nakshatra_key}.${item.name}`,
        rajas:
          item.nakshatra_guna.Rajas !== "0" ? item.nakshatra_guna.Rajas : "",
        tamas:
          item.nakshatra_guna.Tamas !== "0" ? item.nakshatra_guna.Tamas : "",
        sattwa:
          item.nakshatra_guna.Sattwa !== "0" ? item.nakshatra_guna.Sattwa : "",
      }));
    setData1(tableValue);
    percentageCalculation(tableValue);
  };

  const percentageCalculation = (data: any) => {
    const totalCount = data.length;
    const count: any = { Rajas: 0, Tamas: 0, Sattwa: 0 };
    console.log(data);

    data.forEach((item: any) => {
      count.Rajas += item.rajas === "" ? 0 : +item.rajas;
      count.Tamas += item.tamas === "" ? 0 : +item.tamas;
      count.Sattwa += item.sattwa === "" ? 0 : +item.sattwa;
    });

    const percentages: any = {
      Rajas: (count.Rajas / 39) * 100,
      Tamas: (count.Tamas / 39) * 100,
      Sattwa: (count.Sattwa / 39) * 100,
    };

    const percentageData = Object.keys(percentages).map((key) => ({
      key,
      value: `${percentages[key].toFixed(2)} %`,
      percentage: percentages[key].toFixed(2),
      total: count[key],
    }));

    setData2(percentageData);
    setData3(getMaxObjects(percentageData));

    const formattedData = [
      ["key", "value"],
      ...percentageData.map((item) => [item.key, parseFloat(item.percentage)]),
    ];
    setData4(formattedData);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getMaxObjects = (data: any[]) => {
    const max = Math.max(...data.map((item) => +item.percentage));
    return data.filter((item) => +item.percentage === max);
  };

  const getGunas = () => {
    return (
      <NakshatraAnalysis
        chartTitle={"gunasNakshatraTitle"}
        chartData={data4}
        chartSlices={[
          { color: "#dc3912" },
          { color: "#3366cc" },
          { color: "#ff9900" },
        ]}
        tableHeader1={tableHeader1}
        tableData1={data1}
        tableHeader2={tableHeader2}
        tableData2={data2}
        tableHeader3={tableHeader3}
        tableData3={data3}
      />
    );
  };

  return getGunas();
};

export default Gunas;
