import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const appFooterStyles = {
  footer: {
    // marginTop: "25px",
    pt: 5,
    pb: 2,
    borderTop: "1px solid white",
    background: "#070530",
  },
  headTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  headBox: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    borderBottom: "1px solid white",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  contentTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  linkTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  footerTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    color: "#FFFFFF",
  },
  footerSubTypo: {
    color: "#FFFFFF",
    cursor: "pointer",
    ...regularFont,
    "&:hover": {
      color: "#f15e22",
      transition: "all 0.5s ease",
      textShadow: "0 0 15px #f15e22",
    },
  },
  iconWrapper: {
    paddingTop: "7px",
    paddingLeft: "8px",
  },
} as const;

export default appFooterStyles;
