import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import PlanetsFlipCards from "./PlanetsFlipCards";
import { useTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../../helpers/methods";
import { getPlanetData } from "../../AnalysisService";

interface CustomProps {
  profileId?: string;
  setIsLoading: Function;
  house: any;
}

const Planets = (props: CustomProps) => {
  const [planetsData, setPlanetsData] = useState<any[]>([]);
  const { i18n } = useTranslation();

  const planets = [
    "Ascendant",
    "Sun",
    "Moon",
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto",
    "Rahu",
    "Ketu",
  ];

  useEffect(() => {
    getPlanets();
  }, [i18n.language, props.house]);

  const getPlanets = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getPlanetData(props?.profileId!, language);
      setPlanetsData(response[props.house].rashiDetails);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  return (
    <Grid container py={1}>
      {planetsData
        ?.map((data: any) => {
          if (planets.includes(data.point_name)) {
            return <PlanetsFlipCards flipCardData={data} />;
          } else return undefined;
        })
        .filter(Boolean)}
    </Grid>
  );
};

export default Planets;
