import { getRelativeFontSize, mediumFont } from "../../../../utils/styles";

const nakshatraStyles = {
  mainContainer: {
    p: 2,
  },
  mainBox: {
    p: 2,
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
  },
  descriptionText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: "#ffffff",
  },
  tableWrapper: {
    display: "grid",
  },
} as const;

export default nakshatraStyles;
