import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import hidePasswordIcon from "../../assets/images/Hide.svg";
import showPasswordIcon from "../../assets/images/Show.svg";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "../../global/components";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import history from "../../utils/history";
import { getRelativeFontSize } from "../../utils/styles";
import loginStyles from "./Auth.styles";
import { deleteAccount } from "./AuthService";
import { loginForm, loginValidation } from "./AuthTypesAndValidation";

const DeleteAccount = () => {
  const classes = loginStyles;
  const emailRegex = strings.regex;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(loginForm);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleDeleteAccount = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const email = formFields.email.value.toLowerCase();
        const password = formFields.password.value;
        const response = await deleteAccount(email, password);
        history.push(urls.homeViewPath);
        setIsLoading(false);
        openSuccessNotification(response.message);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleDeleteAccount();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDeleteAccount = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={"space-between"}
        >
          <Typography sx={classes.getHeading}> {t("deleteAccount")}</Typography>
        </Stack>
        <Typography pt={1} sx={classes.welcomeTypo}>
          {t("deleteAccountContent4")}
        </Typography>

        <Box sx={classes.innerGetLoginBox} pt={{ sm: 7, xs: 5 }}>
          <CustomInput
            required
            label={t("email")}
            placeHolder={t("emailPlace")}
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box mt={2}>
            <CustomInput
              required
              label={t("password")}
              placeHolder="••••••••"
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formFields.password.value}
              onChange={handleOnChangeInputField}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <Box
                        component="img"
                        src={showPassword ? showPasswordIcon : hidePasswordIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                !isTruthy(formFields.password.value) &&
                formFields.password.error
              }
            />
          </Box>
          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label={t("delete")}
              type={strings.PRIMARY}
              loading={isLoading}
              onClick={() => setOpen(true)}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {t("deleteAccountHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} pb={5} spacing={2} sx={classes.dialogContent}>
        <Typography
          sx={{
            ...classes.lightFont,
            textAlign: "center",
            fontSize: getRelativeFontSize(5),
          }}
          pt={2}
        >
          {t("deleteAccountContent")}
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={2}
          alignSelf={"center"}
        >
          <Button onClick={handleClose} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText"> {t("no")}</Typography>
            </Box>
          </Button>
          <Button onClick={handleDeleteAccount} sx={classes.logoutBtn}>
            <Typography className="btnText"> {t("yes")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };

  const getForgotPasswordDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  return (
    <Box px={1} sx={classes.scrollElement}>
      {getDeleteAccount()}
      {getForgotPasswordDialog()}
    </Box>
  );
};

export default DeleteAccount;
