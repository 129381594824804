import { Grid, Stack, Typography } from "@mui/material";
import analysisStyles from "../../Analysis.styles";
import { useTranslation } from "react-i18next";

const MineralsLegends = () => {
  const classes = analysisStyles;
  const { t } = useTranslation();

  return (
    <Stack direction={"column"}>
      <Grid container sx={classes.legendContainer}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("mineralsLegend")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("mineralsLegendDesc1")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("mineralsLegendDesc2")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("mineralsLegendDesc3")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("mineralsLegendDesc4")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("mineralsLegendDesc5")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MineralsLegends;
