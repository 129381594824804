import {
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import analysisStyles from "../../Analysis.styles";
import { useTranslation } from "react-i18next";

const MahadashaDetails = () => {
  const classes = analysisStyles;
  const { t } = useTranslation();

  const headers = [
    `${t("dashaPlanet")}`,
    `${t("yearSpan")}`,
    `${t("nakshatraHead")}`,
  ];

  const rows = [
    {
      dashaPlanet: `${t("ketu")}`,
      yearSpan: `${t("ketuYear")}`,
      nakshatraHead: `${t("ketuNakshatra")}`,
    },
    {
      dashaPlanet: `${t("venus")}`,
      yearSpan: `${t("venusYear")}`,
      nakshatraHead: `${t("venusNakshatra")}`,
    },
    {
      dashaPlanet: `${t("sun")}`,
      yearSpan: `${t("sunYear")}`,
      nakshatraHead: `${t("sunNakshatra")}`,
    },
    {
      dashaPlanet: `${t("moon")}`,
      yearSpan: `${t("moonYear")}`,
      nakshatraHead: `${t("moonNakshatra")}`,
    },
    {
      dashaPlanet: `${t("mars")}`,
      yearSpan: `${t("marsYear")}`,
      nakshatraHead: `${t("marsNakshatra")}`,
    },
    {
      dashaPlanet: `${t("rahu")}`,
      yearSpan: `${t("rahuYear")}`,
      nakshatraHead: `${t("rahuNakshatra")}`,
    },
    {
      dashaPlanet: `${t("jupiter")}`,
      yearSpan: `${t("jupiterYear")}`,
      nakshatraHead: `${t("jupiterNakshatra")}`,
    },
    {
      dashaPlanet: `${t("saturn")}`,
      yearSpan: `${t("saturnYear")}`,
      nakshatraHead: `${t("saturnNakshatra")}`,
    },
    {
      dashaPlanet: `${t("mercury")}`,
      yearSpan: `${t("mercuryYear")}`,
      nakshatraHead: `${t("mercuryNakshatra")}`,
    },
  ];

  const getPoints = () => {
    return (
      <Stack direction={"column"} pb={6}>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("careerHead")}
              </Typography>
              <Typography sx={classes.labelTypo}>{t("careerBody")}</Typography>
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={classes.legendDivider} />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("mentalHead")}
              </Typography>
              <Typography sx={classes.labelTypo}>{t("mentalBody")}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("relationshipsHead")}
              </Typography>
              <Typography sx={classes.labelTypo}>
                {t("relationshipsBody")}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #ffffff54",
            }}
          />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("spiritualHead")}
              </Typography>
              <Typography sx={classes.labelTypo}>
                {t("spiritualBody")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("selfUnderstandingHead")}
              </Typography>
              <Typography sx={classes.labelTypo}>
                {t("selfUnderstandingBody")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Typography pt={3} sx={classes.labelTypo}>
          {t("pointsConclusion")}
        </Typography>
      </Stack>
    );
  };

  const getTable = () => {
    return (
      <Box px={1}>
        <TableContainer component={Paper} sx={classes.planetTableContainer}>
          <Table>
            <TableHead sx={classes.planetTableHead}>
              <TableRow sx={{ borderBottom: "1px solid #ffffff4f" }}>
                {headers.map((header: string) => {
                  return (
                    <TableCell
                      sx={{
                        ...classes.tableHeader,
                        borderRight: "1px solid #ffffff4f",
                      }}
                    >
                      {header}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={classes.planetTableBody}>
              {rows.map((row) => (
                <TableRow key={row.dashaPlanet}>
                  <TableCell component="th" sx={classes.tableBody}>
                    {row.dashaPlanet}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...classes.tableBody,
                      borderRight: "1px solid #ffffff4f",
                    }}
                  >
                    {row.yearSpan}
                  </TableCell>
                  <TableCell sx={classes.tableBody}>
                    {row.nakshatraHead}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography pt={3} sx={classes.labelTypo}>
          {t("planetDesc")}
        </Typography>
      </Box>
    );
  };

  const getPlanetDetails = () => {
    return (
      <Stack direction={"column"} pb={6}>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("ketu")} - {t("ketuYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("ketuDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("ketuDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("ketuDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("ketuDesc4")}
              </Typography>
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={classes.legendDivider} />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("venus")} - {t("venusYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("venusDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("venusDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("venusDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("venusDesc4")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("sun")} - {t("sunYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("sunDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("sunDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("sunDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("sunDesc4")}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #ffffff54",
            }}
          />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("moon")} - {t("moonYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("moonDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("moonDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("moonDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("moonDesc4")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("mars")} - {t("marsYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("marsDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("marsDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("marsDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("marsDesc4")}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #ffffff54",
            }}
          />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("rahu")} - {t("rahuYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("rahuDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("rahuDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("rahuDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("rahuDesc4")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid
            item
            xl={5.9}
            lg={5.9}
            md={12}
            sm={12}
            xs={12}
            sx={classes.legendsGrid}
          >
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("jupiter")} - {t("jupiterYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("jupiterDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("jupiterDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("jupiterDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("jupiterDesc4")}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #ffffff54",
            }}
          />
          <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("saturn")} - {t("saturnYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("saturnDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("saturnDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("saturnDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("saturnDesc4")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={classes.legendContainer}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack direction="column" px={1} py={3}>
              <Typography sx={classes.valueTypo} pb={2}>
                {t("mercury")} - {t("mercuryYear")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("mercuryDesc1")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("mercuryDesc2")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("mercuryDesc3")}
              </Typography>
              <Typography
                sx={{ ...classes.labelTypo, display: "list-item", ml: 2 }}
              >
                {t("mercuryDesc4")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Typography pt={3} sx={classes.labelTypo}>
          {t("detail3Conclusion")}
        </Typography>
      </Stack>
    );
  };

  const getMahadashaDetails = () => {
    return (
      <Box sx={classes.mahadashaContentWrapper} py={3}>
        <Typography sx={classes.detailsTypo}>
          {t("mahadashaDetail1")}
        </Typography>
        {getPoints()}
        <Typography pb={3} sx={classes.detailsTypo}>
          {t("mahadashaDetail2")}
        </Typography>
        {getTable()}
        <Typography pt={4} pb={3} sx={classes.detailsTypo}>
          {t("mahadashaDetail3")}
        </Typography>
        {getPlanetDetails()}
      </Box>
    );
  };

  return getMahadashaDetails();
};

export default MahadashaDetails;
