import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTabs from "../../../../global/components/CustomTabs/CustomTabs1";
import { handleErrorMessage } from "../../../../helpers/methods";
import i18n from "../../../../i18n";
import { getPlanetData } from "../../AnalysisService";
import DoshaPada from "./components/DoshaPada";
import ElementPada from "./components/ElementPada";
import Gunas from "./components/Gunas";
import Motivation from "./components/Motivation";
import Orientation from "./components/Orientaion";
import TemperamentPada from "./components/TemperamentPada";
import Trimurti from "./components/Trimurti";

interface CustomProps {
  profileId: string;
  setIsLoading: Function;
}

const Nakshatra = (props: CustomProps) => {
  const { t } = useTranslation();
  const language = i18n.language.toLowerCase();
  const [tabValue, setTabValue] = useState(`${t("temperamentTab")}`);
  const [data, setData] = useState();

  const tabConfig = [
    {
      label: `${t("temperamentTab")}`,
    },
    {
      label: `${t("elementTab")}`,
    },
    {
      label: `${t("doshaTab")}`,
    },
    {
      label: `${t("trimurtiTab")}`,
    },
    {
      label: `${t("motivationTab")}`,
    },
    {
      label: `${t("orientationTab")}`,
    },
    {
      label: `${t("gunasTab")}`,
    },
  ];

  const planets = [
    "Ascendant",
    "Sun",
    "Moon",
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto",
    "Rahu",
    "Ketu",
  ];

  useEffect(() => {
    getPlanetsData();
  }, []);

  useEffect(() => {
    setTabValue(`${t("temperamentTab")}`);
  }, [i18n.language]);

  const getPlanetsData = async () => {
    try {
      props.setIsLoading(true);
      const response = await getPlanetData(props?.profileId!, language);
      setData(response.D1.rashiDetails);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const getComponentBasedOnValue = () => {
    switch (tabValue) {
      case `${t("temperamentTab")}`:
        return <TemperamentPada data={data} planets={planets} />;
      case `${t("elementTab")}`:
        return <ElementPada data={data} planets={planets} />;
      case `${t("doshaTab")}`:
        return <DoshaPada data={data} planets={planets} />;
      case `${t("trimurtiTab")}`:
        return <Trimurti data={data} planets={planets} />;
      case `${t("motivationTab")}`:
        return <Motivation data={data} planets={planets} />;
      case `${t("orientationTab")}`:
        return <Orientation data={data} planets={planets} />;
      case `${t("gunasTab")}`:
        return <Gunas data={data} planets={planets} />;
      default:
        return <TemperamentPada data={data} planets={planets} />;
    }
  };

  const getNakshatra = () => {
    return (
      <Box pt={2}>
        <CustomTabs
          changeValue={handleChange}
          selected={tabValue}
          tabConfig={tabConfig}
        />
        {data && getComponentBasedOnValue()}
      </Box>
    );
  };

  return getNakshatra();
};

export default Nakshatra;
