import { Box, Skeleton, Typography } from "@mui/material";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { regularFont } from "../../../../../utils/styles";
import nakshatraStyles from "../Nakshatra.styles";

interface CustomProps {
  data: any[];
  title: string;
  slices: any[];
  maxData: any[];
  placeColor?: string;
}

const PieChart = (props: CustomProps) => {
  const classes = nakshatraStyles;
  const { t } = useTranslation();
  const options = {
    title: props.title,
    titleTextStyle: { ...regularFont, color: "#fff" },
    is3D: true,
    pieStartAngle: 0,
    legend: {
      position: "labeled",
      textStyle: { ...regularFont, color: "white", bold: 1, fontSize: 16 },
    },
    pieHole: 0.1,
    pieSliceTextStyle: {
      ...regularFont,
      color: props.placeColor ? props.placeColor : "#fff",
    },
    slices: props.slices,
    export: { enabled: true },
  };

  return (
    <div className="chart-container">
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="PieChart"
        loader={
          <Box px={10} sx={{ display: "flex", justifyContent: "center" }}>
            <Skeleton
              variant="circular"
              width={500}
              height={400}
              sx={{
                background: "#ffffff70",
              }}
            />
          </Box>
        }
        data={props.data}
        options={options}
        className="custom-pie-chart"
      />
      {props.maxData.map((data: any, index: number) => {
        return (
          <Typography sx={classes.descriptionText} px={5} pb={2}>
            <u style={{ fontSize: "18px" }}>{data.key}:</u>{" "}
            {t(`${data.key.split(" ")[0].toLowerCase()}Details`)}
          </Typography>
        );
      })}
    </div>
  );
};

export default PieChart;
