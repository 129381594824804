import {
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const pricingStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    marginY: "25px",
    maxWidth: "1750px",
    width: "100%",
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    // ...centerItemFlex,
    display: "grid",
    alignContent: "center",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 240px)",
    //   p: 1,
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  // mainBox: {
  //   p: 1,
  //   borderRadius: "20px",
  //   height: "calc(100vh - 220px)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   background: "rgba(255, 255, 255, 0.07)",
  //   backdropFilter: "blur(12px)",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 222px)",
  //   },
  // },
  // subBox: {
  //   p: 3,
  //   height: "calc(100vh - 270px)",
  //   borderRadius: "20px",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   "&::-webkit-scrollbar": {
  //     width: "5px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     borderRadius: "10px",
  //     border: "1px solid #FFFFFF",
  //     background: "#282945",
  //   },
  //   border: "1px solid rgb(111 111 111 / 22%)",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 240px)",
  //     p: 1,
  //   },
  // },
  priceBox: {
    borderRadius: "8px",
    // border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    // backdropFilter: "blur(24px)",
    boxShadow: "inset 0 1000px rgba(255, 255, 255, .08)",
    px: 2,
    py: 4,
  },
  planText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#ffffff",
  },
  priceText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(5),
    },
  },
  durationText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "#ffffff66",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  planDescText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#FFFFFF99",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  featuresText: {
    ...regularFont,
    fontSize: getRelativeFontSize(5),
    color: "#ffffff",
    display: "list-item",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  subscribeBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#9053F6",
    width: "100%",
    maxWidth: "700px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    "&:disabled": {
      background: "#c09aff",
    },
  },
  subscribeBtnTypo: {
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  loadingStyle: {
    color: "#000000",
    width: "25px !important",
    height: "25px !important",
  },
  cardElementStyle: {
    borderRadius: "8px",
    border: "1px solid #FFF",
    background: "rgba(255, 255, 255)",
    backdropFilter: "blur(5.5px)",
    p: 2,
  },
  inputLabels: {
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
    color: "#FFFFFF",
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: "45px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  planBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#FDFDFD",
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #9053F6",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
      color: "#9053F6",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  selectedPlanBtn: {
    background: "#9053F6",
    color: "#FDFDFD",
    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
  },
  recommendedPlan: {
    height: "1px",
    zIndex: 2,
    textAlign: "end",
    position: "relative",
    pr: 3,
  },
  recommendsText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#FFFFFF",
    textAlign: "center",
    pb: 1,
  },
  label: { display: "flex", height: "20px" },
  labelText: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  textField: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #FFF",
    background: "rgba(255, 255, 255)",
    backdropFilter: "blur(5.5px)",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#000000",
      "&::placeholder": {
        ...regularFont,
        color: "#000000AF",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#FFFFFF",
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
  },
  normalTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(5),
    color: "#ffffffc7",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  boldTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "#ffffff",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  dividerStyle: {
    border: "1px solid #ffffff",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  invoiceDetailsMainBox: {
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.10)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(-1px)",
    width: "100%",
    // height: "50px",
    p: 1,
  },
  invoiceDetailsSubBox: {
    borderRadius: "16px",
    border: "1px solid rgba(255, 255, 255, 0.10)",
    background:
      "radial-gradient(70.71% 70.71% at 50% 50%, rgba(168, 127, 255, 0.04) 0%, rgba(168, 127, 255, 0.00) 100%), rgba(4, 1, 21, 0.10)",
    backdropFilter: "blur(-1px)",
    width: "100%",
    // height: "48px",
  },
  titleTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffffc7",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  valueTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  footer: {
    ...centerItemFlex,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    textAlign: "center",
    color: "#FFFFFF",
  },
  content: {
    width: "100%",
    minHeight: "calc(100vh - 165px)",
    height: "auto",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      minHeight: "calc(100vh - 100px)",
    },
  },
} as const;

export default pricingStyles;
