import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import resetPasswordStyles from "./ResetPassword.styles";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { useState } from "react";
import {
  resetPasswordForm,
  resetPasswordValidation,
} from "../Auth/AuthTypesAndValidation";
import { updatePassword } from "./ResetPasswordService";
import {
  handleErrorMessage,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../helpers/methods";
import hidePasswordIcon from "../../assets/images/Hide.svg";
import showPasswordIcon from "../../assets/images/Show.svg";
import { store } from "../../utils/store";
import { logOutAction } from "../../redux/authSlice";
import urls from "../../global/constants/UrlConstants";
import history from "../../utils/history";
import { CustomButton, CustomInput } from "../../global/components";
import strings from "../../global/constants/StringConstants";
import { theme } from "../../utils/styles";
import backArrow from "../../assets/icons/backArrow.svg";

const UpdatePassword = () => {
  const classes = resetPasswordStyles;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState(resetPasswordForm);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleLogout = () => {
    setTimeout(() => {
      history.push(urls.homeViewPath);
      store.dispatch(logOutAction());
    }, 1000);
  };

  const handleSetPassword = async () => {
    try {
      if (handleValidation()) {
        if (formFields.password.value === formFields.confirmPassword.value) {
          const passwordData = {
            oldPassword: formFields.oldPassword.value,
            newPassword: formFields.password.value,
          };
          setIsLoading(true);
          const response = await updatePassword(passwordData);
          openSuccessNotification(response.message);
          setIsLoading(false);
          handleLogout();
        } else {
          openErrorNotification("Password and Confirm password doesn't match");
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClickShowPassword = (field: string) => {
    if (field === "password") {
      setShowPassword(showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(showConfirmPassword);
    } else {
      setShowOldPassword(showOldPassword);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSetPassword();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowOldPassword(!showOldPassword);
    }
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = resetPasswordValidation(formFields, true);
    setFormFields({ ...errors });
    return isValid;
  };

  const getHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { sm: "center", xs: "baseline" },
          width: "100%",
        }}
        pb={3}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => history.goBack()}
        >
          {isMobile ? (
            <img src={backArrow} height={"20px"} alt="Module not found..." />
          ) : (
            <img src={backArrow} height={"25px"} alt="Module not found..." />
          )}
        </Box>
        <Typography sx={classes.italicTypo}>{t("updatePassword")}</Typography>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <Box>
        <Box mt={{ sm: 5, xs: 2 }}>
          <CustomInput
            required
            label={t("oldPassword")}
            placeHolder="••••••••"
            id="oldPassword"
            type={showOldPassword ? "text" : "password"}
            name="oldPassword"
            value={formFields.oldPassword.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("oldPassword")}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleMouseDownPassword(event, "oldPassword")
                    }
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={
                        showOldPassword ? showPasswordIcon : hidePasswordIcon
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              !isTruthy(formFields.oldPassword.value) &&
              formFields.oldPassword.error
            }
          />
        </Box>
        <Box mt={{ sm: 5, xs: 2 }}>
          <CustomInput
            required
            label={t("newPassword")}
            placeHolder="••••••••"
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formFields.password.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password")}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleMouseDownPassword(event, "password")
                    }
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={showPassword ? showPasswordIcon : hidePasswordIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              !isTruthy(formFields.password.value) && formFields.password.error
            }
          />
        </Box>
        <Box mt={{ sm: 5, xs: 2 }}>
          <CustomInput
            required
            label={t("confirmPassword")}
            placeHolder="••••••••"
            id="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formFields.confirmPassword.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmPassword")}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleMouseDownPassword(event, "confirmPassword")
                    }
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={
                        showConfirmPassword
                          ? showPasswordIcon
                          : hidePasswordIcon
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              !isTruthy(formFields.confirmPassword.value) &&
              formFields.confirmPassword.error
            }
          />
        </Box>

        <Box marginTop={"50px"} width={"100%"} textAlign={"center"}>
          <CustomButton
            label={t("submit")}
            type={strings.SECONDARY}
            loading={isLoading}
            onClick={handleSetPassword}
          />
        </Box>
      </Box>
    );
  };

  const getUpdatePassword = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="sm">
                {getHeader()}
                {getContent()}
              </Container>
            </Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };
  return getUpdatePassword();
};

export default UpdatePassword;
