import FootballGroundBackground from "../../../../assets/images/FootballGroundBackground.png";
import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../../utils/styles";

const footballStyles = {
  footballFormWrapper: {
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    alignItems: "center",
    height: "100%",
    minHeight: "150px",
    justifyContent: "end",

    // cursor: "pointer",
    transformStyle: "preserve-3d",
    transformOrigin: "center right",
    transition: "transform 1s",
    // "&.is-flipped": {
    //   // transform: "translateX(-100%) rotateY(-180deg)",
    //   WebkitTransform: "rotateY(-180deg) translate(100%, 0)",
    //   transform: "rotateY(-180deg) translate(100%, 0)",
    // },

    [theme.breakpoints.down("xl")]: {
      minHeight: "175px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "155px",
    },
  },
  darkBackground: {
    background: "#2f3749",
    position: "relative",
    boxShadow: "inset 0px 12px 4px -11px #474a51",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(40),
    color: "#ffffff",
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(20),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  gradientText: {
    margin: "0 auto",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  mainBox: {
    background: "#2C5F00",
    height: "100vh",
    position: "relative",
  },
  headerWrapper: {
    p: 5,
    zIndex: 2,
    height: "150px",
    [theme.breakpoints.down("md")]: {
      p: 2,
      height: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "125px",
    },
    [theme.breakpoints.down(370)]: {
      height: "150px",
    },
  },
  arrowImage: {
    maxWidth: "600px",
    width: "25%",
  },
  predicationText: {
    ...regularFont,
    fontSize: getRelativeFontSize(9),
    color: "#ffffff",
    textAlign: "center",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(5),
      width: "100%",
    },
  },
  contactBtn: {
    border: "none",
    minWidth: "-webkit-fill-available",
    height: "50px !important",
  },
  mainWrapper: {
    mx: 5,
    px: 4,
    pb: 4,
    background: "#effbec",
    borderRadius: "10px",
    position: "relative",
    // bottom: "150px",
    zIndex: 0,
    minHeight: "72vh",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "70vh",
    },
    [theme.breakpoints.down("sm")]: {
      mx: 2,
      p: 2,
      // bottom: "180px",
    },
  },
  formWrapper: {
    border: "1px solid #18803F",
    borderRadius: "10px",
    p: 3,
    minHeight: "calc(72vh - 50px)",
    alignContent: "center",
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: getRelativeFontSize(40),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(30),
    },
  },
  formStyle: {
    px: 2,
    height: "calc(100vh - 255px)",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 205px)",
    },
  },
  textField: {
    width: "100%",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(5.5px)",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "1px solid #FFFFFF",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "10px",

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  nameField: {
    color: "rgba(255,255,255,.5)",
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  nameTextField: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  btnStyle: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  btnBox: {
    background: "#060b25",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  icon: {
    fill: "#FFFFFF",
  },
  searchPlaceInputWrapper: {
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#060b25,#060b25),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",
    zIndex: 0,
  },
  searchPlaceInput: {
    ...mediumFont,
    padding: "15px 10px",
    width: "-webkit-fill-available",
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    outline: "none",
    color: "#FFFFFF",
    zIndex: 1,
  },
  errorStyling: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  groundBackground: {
    position: "relative",
    backgroundImage: `url(${FootballGroundBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
    minHeight: "90vh",
    overflow: "hidden",
    borderRadius: "20px",
    "&:before": {
      zIndex: 0,
      position: "absolute",
      backgroundColor: "#76b1438c",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "100vh",
    },
  },
  whiteBoxStyles: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#98e184",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    zIndex: 1,
  },
  labelStyles: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
  },
  tshirt: {
    position: "relative",
    width: "40px",
    height: "60px",
    backgroundColor: "#ffffff",
    borderRadius: "0px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    // overflow: "hidden",
  },
  hole: {
    position: "absolute",
    width: "35px",
    height: "35px",
    backgroundColor: "#ffdbac",
    // backgroundImage:
    //   "linear-gradient(180deg, rgb(255 255 255) 50%, #f0f0f0 50%)",
    borderRadius: "50%",
    top: "-18px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  },
  // sleeve: {
  //   position: "absolute",
  //   width: "100px",
  //   height: "60px",
  //   backgroundColor: "#0000ff",
  //   top: "50px",
  //   border: "5px solid #ff0000",
  //   borderTop: "none",
  //   borderRadius: "0 0 50% 50%",
  // },
  // sleeveLeft: {
  //   left: "-60px",
  //   transform: "rotate(-45deg)",
  // },
  // sleeveRight: {
  //   right: "-60px",
  //   transform: "rotate(45deg)",
  // },
  arm: {
    position: "relative",
    width: "15px",
    height: "30px",
    backgroundColor: "#ffffff",
    borderRadius: "0px",
  },
  armLeft: {
    right: "7px",
    top: "3px",
    transform: "rotate(55deg)",
  },
  armRight: {
    left: "32px",
    transform: "rotate(125deg)",
    bottom: "27px",
  },
  body: {
    ...regularFont,
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    fontSize: "3em",
    bottom: "60px",
  },
  number: {
    // position: "absolute",
    // top: "70px",
    ...regularFont,
    fontSize: getRelativeFontSize(3),
  },
  positionName: {
    ...regularFont,
    textAlign: "center",
    fontSize: getRelativeFontSize(1),
    color: "#ffffff",
    position: "relative",
    bottom: "60px",
    letterSpacing: "2px",
  },
  noteText: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    color: "rgba(255,255,255,.5)",
  },
  boldNoteText: {
    ...boldFont,
    color: "#1A72B1",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  dialogHeaderText: {
    ...boldFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(10),
    borderBottom: "1px solid #fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  dialogBoldFont: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(7),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(5),
    },
  },
  dialogSubBoldFont: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  dialogRegularFont: {
    ...regularFont,
    color: "#ffffffb3",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
} as const;

export default footballStyles;
