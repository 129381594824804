export const years: any[] = [];

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

for (let i = currentYear + 15; i > 0; i--) {
  years.push({ value: i, content: i });
}

export const days: any[] = [];

for (let i = 1; i <= 31; i++) {
  days.push({ value: i, content: i });
}

export const hours: any[] = [];

for (let i = 1; i <= 12; i++) {
  if (i === 12) {
    hours.push({ content: i + " AM", value: 0 });
  } else {
    hours.push({ content: i + " AM", value: i });
  }
}

for (let i = 1; i <= 12; i++) {
  if (i === 12) {
    hours.push({ content: i + " PM", value: 12 });
  } else {
    hours.push({ content: i + " PM", value: i + 12 });
  }
}

export const minutesSeconds: any[] = [];

for (let i = 0; i <= 59; i++) {
  minutesSeconds.push({ value: i, content: i });
}

export const formData = () => {
  return {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    bloodGroup: {
      value: "0",
      error: "",
    },
    sex: {
      value: "Man",
      error: "",
    },
    year: {
      value: "0",
      error: "",
    },
    month: {
      value: "0",
      error: "",
    },
    date: {
      value: "0",
      error: "",
    },
    hours: {
      value: "-1",
      error: "",
    },
    minutes: {
      value: "-1",
      error: "",
    },
    seconds: {
      value: "0",
      error: "",
    },
    birthPlace: {
      value: "",
      error: "",
    },
    key: {
      value: "",
      error: "",
    },
    latitude: {
      value: "",
      error: "",
    },
    longitude: {
      value: "",
      error: "",
    },
    timezone: {
      value: "",
      error: "",
    },
    pregnancy: {
      value: "9",
      error: "",
    },
  } as any;
};
