import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import buyNowIcon from "../../../../assets/icons/buyNowIcon.svg";
import mineralsImage from "../../../../assets/images/mineralsImage.svg";
import { handleErrorMessage } from "../../../../helpers/methods";
import { LightTooltip, theme } from "../../../../utils/styles";
import analysisStyles from "../../Analysis.styles";
import { getBuyNowUrl, getMineralsData } from "../../AnalysisService";
import MineralsFlipCards from "./MineralsFlipCards";

interface CustomProps {
  profileId: string;
  setIsLoading: Function;
}

const MineralsCards = (props: CustomProps) => {
  const classes = analysisStyles;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();
  const [mineralsData, setMineralsData] = useState([]);

  useEffect(() => {
    getMinerals();
  }, [i18n.language]);

  const getMinerals = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getMineralsData(props?.profileId!, language);
      setMineralsData(response.missingMinerals);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getStoreUrls = async (store: string) => {
    try {
      props.setIsLoading(true);
      const urls = await getBuyNowUrl();
      window.open(store === "store1" ? urls.webUrl1 : urls.webUrl2, "_blank");
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  return (
    <Grid
      container
      justifyContent={"space-evenly"}
      alignItems={"center"}
      columnGap={"15px"}
      rowGap={"20px"}
      py={2}
    >
      <Grid item xl={3} lg={4} md={12} sm={12} xs={12} height={"100%"}>
        <Box sx={classes.mineralsCardStyle}>
          {isMobile ? (
            <img
              src={mineralsImage}
              width={"100%"}
              height={"300px"}
              alt="Module Not Found..."
            />
          ) : (
            <img
              src={mineralsImage}
              width={"100%"}
              height={"500px"}
              alt="Module Not Found..."
            />
          )}
        </Box>
      </Grid>
      <Grid item xl={8.5} lg={7} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          {mineralsData?.map((data: any) => {
            return <MineralsFlipCards flipCardData={data} />;
          })}
        </Grid>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display={isTablet ? "content" : "flex"}
        justifyContent={"center"}
      >
        <Box
          display={isTablet ? "grid" : "flex"}
          justifyContent={"center"}
          py={isTablet ? 2 : 0}
          rowGap={"15px"}
        >
          <LightTooltip
            placement="top"
            arrow
            sx={{
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#ffffff",
                fontSize: "16px",
                color: "#000000",
              },
            }}
            title="Buy Now From Store 1"
          >
            <Button
              sx={classes.btnStyle}
              onClick={() => getStoreUrls("store1")}
            >
              <Box sx={classes.btnBox}>
                <Typography className="btnText">
                  <img
                    src={buyNowIcon}
                    alt="Module Not Found..."
                    style={{ paddingRight: "10px" }}
                  />
                  {t("shop1")}
                </Typography>
              </Box>
            </Button>
          </LightTooltip>
          <LightTooltip
            placement="top"
            arrow
            sx={{
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#ffffff",
                fontSize: "16px",
                color: "#000000",
              },
            }}
            title="Buy Now From Store 2"
          >
            <Button
              onClick={() => getStoreUrls("store2")}
              sx={classes.btnStyle}
            >
              <Box sx={classes.btnBox}>
                <Typography className="btnText">
                  <img
                    src={buyNowIcon}
                    alt="Module Not Found..."
                    style={{ paddingRight: "10px" }}
                  />
                  {t("shop2")}
                </Typography>
              </Box>
            </Button>
          </LightTooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MineralsCards;
