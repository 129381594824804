import { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import calculatorStyle from "./Calculator.styles";
import { CustomButton, CustomInput } from "../../global/components";
import { days, formData, hours, minutesSeconds, years } from "./CalculatorData";
import { handleErrorMessage, isTruthy } from "../../helpers/methods";
import { addProfile } from "./CalculatorService";
import urls from "../../global/constants/UrlConstants";
import history from "../../utils/history";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { useTranslation } from "react-i18next";
import strings from "../../global/constants/StringConstants";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import backArrow from "../../assets/icons/backArrow.svg";
import { theme } from "../../utils/styles";

const Calculator = () => {
  const classes = calculatorStyle;
  const isFirstRender = useRef(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });
  const [formFields, setFormFields] = useState(formData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        birthPlace: {
          ...formFields.birthPlace,
          value: address.location,
        },
      });
    !formFields.birthPlace.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  useEffect(() => {
    if (!isFirstRender.current) {
      handleValidation();
    } else {
      isFirstRender.current = false;
    }
  }, [i18n.language]);

  const bloodGroupMenu = [
    { value: 0, content: `${t("optional")}`, disabled: true },
    { value: "A+", content: "A+" },
    { value: "A-", content: "A-" },
    { value: "B+", content: "B+" },
    { value: "B-", content: "B-" },
    { value: "O+", content: "O+" },
    { value: "O-", content: "O-" },
    { value: "AB+", content: "AB+" },
    { value: "AB-", content: "AB-" },
  ];

  const genderMenu = [
    { value: "Man", content: `${t("man")}` },
    { value: "Woman", content: `${t("woman")}` },
  ];

  const yearMenu = [
    { value: 0, content: `${t("year")}`, disabled: true },
    ...years,
  ];

  const monthMenu = [
    { value: 0, content: `${t("month")}`, disabled: true },
    { content: `${t("january")}`, value: 1 },
    { content: `${t("february")}`, value: 2 },
    { content: `${t("march")}`, value: 3 },
    { content: `${t("april")}`, value: 4 },
    { content: `${t("may")}`, value: 5 },
    { content: `${t("june")}`, value: 6 },
    { content: `${t("july")}`, value: 7 },
    { content: `${t("august")}`, value: 8 },
    { content: `${t("september")}`, value: 9 },
    { content: `${t("october")}`, value: 10 },
    { content: `${t("november")}`, value: 11 },
    { content: `${t("december")}`, value: 12 },
  ];

  const dateMenu = [
    { value: 0, content: `${t("date")}`, disabled: true },
    ...days,
  ];

  const hoursMenu = [
    { value: -1, content: `${t("hour")}`, disabled: true },
    ...hours,
  ];

  const minutesMenu = [
    { value: -1, content: `${t("minute")}`, disabled: true },
    ...minutesSeconds,
  ];

  const secondsMenu = [
    { value: -1, content: `${t("second")}`, disabled: true },
    ...minutesSeconds,
  ];

  const pregnancyMenu = [
    { value: -1, content: `${t("month")}`, disabled: true },
    { value: 9, content: `9 ${t("months")}` },
    { value: 8, content: `8 ${t("months")}` },
    { value: 7, content: `7 ${t("months")}` },
    { value: 6, content: `6 ${t("months")}` },
  ];

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  const initialize = () => {
    const input: any = document.getElementById("birthPlace");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  };

  const formValidation = (formData: any, address?: any) => {
    let errors = formData;
    let isValid = true;
    const firstName = formData.firstName.value;
    const year = formData.year.value;
    const month = formData.month.value;
    const date = formData.date.value;
    const hours = formData.hours.value;
    const minutes = formData.minutes.value;
    const seconds = formData.seconds.value;
    const birthPlace = formData.birthPlace.value;
    const latitude = address.latitude;
    const longitude = address.longitude;
    if (!firstName) {
      errors.firstName.error = t("firstNameErrorMessage");
      isValid = false;
    }
    if (year === "0") {
      errors.year.error = t("yearErrorMessage");
      isValid = false;
    }
    if (month === "0") {
      errors.month.error = t("monthErrorMessage");
      isValid = false;
    }
    if (date === "0") {
      errors.date.error = t("dateErrorMessage");
      isValid = false;
    }
    if (hours === "-1") {
      errors.hours.error = t("hourErrorMessage");
      isValid = false;
    }
    if (minutes === "-1") {
      errors.minutes.error = t("minuteErrorMessage");
      isValid = false;
    }
    if (seconds === "-1") {
      errors.seconds.error = t("secondErrorMessage");
      isValid = false;
    }
    if (!birthPlace) {
      errors.birthPlace.error = t("birthPlaceErrorMessage");
      isValid = false;
    }
    if (!latitude) {
      errors.birthPlace.error = t("latLongErrorMessage");
      isValid = false;
    }
    if (!longitude) {
      errors.birthPlace.error = t("latLongErrorMessage");
      isValid = false;
    }
    return { isValid, errors };
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(formFields, address);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleSaveProfile = async () => {
    try {
      if (handleValidation()) {
        const profileData = {
          firstName: formFields.firstName.value.trim(),
          lastName: formFields.lastName.value.trim(),
          birthDate:
            `${formFields.year.value}-${formFields.month.value}-${formFields.date.value}` +
            " " +
            `${formFields.hours.value}:${formFields.minutes.value}:${formFields.seconds.value}`,
          longitude: address.longitude,
          latitude: address.latitude,
          altitude: "0",
          bloodGroup: formFields.bloodGroup.value,
          timezone: address.timezone,
          pregnancy: +formFields.pregnancy.value,
          sex: formFields.sex.value,
          birthPlace: formFields.birthPlace.value,
        };
        setIsLoading(true);
        const response = await addProfile(profileData);
        // openSuccessNotification(response.message);
        history.push(urls.dashboardViewPath);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleFieldChange = (event: any) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
    if (event.target.name === "birthPlace") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const getForm = () => {
    return (
      <Grid container spacing={1} justifyContent={"center"}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { sm: "center", xs: "baseline" },
              width: "100%",
            }}
            pb={3}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer", width: "fit-content" }}
              onClick={() => history.goBack()}
            >
              {isMobile ? (
                <img
                  src={backArrow}
                  height={"20px"}
                  alt="Module not found..."
                />
              ) : (
                <img
                  src={backArrow}
                  height={"25px"}
                  alt="Module not found..."
                />
              )}
            </Box>
            <Typography sx={classes.italicTypo}>{t("personalData")}</Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomInput
            required
            label={t("firstName")}
            name="firstName"
            value={formFields.firstName.value}
            onChange={handleFieldChange}
            placeHolder={t("firstNamePlaceholder")}
            autoComplete="off"
            error={
              !isTruthy(formFields.firstName.value) &&
              formFields.firstName.error
            }
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomInput
            label={t("lastName")}
            name="lastName"
            value={formFields.lastName.value}
            onChange={handleFieldChange}
            placeHolder={t("lastNamePlaceholder")}
            autoComplete="off"
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            label={t("bloodGroup")}
            menuItems={bloodGroupMenu}
            onChange={handleFieldChange}
            id={"bloodGroup"}
            name={"bloodGroup"}
            value={formFields.bloodGroup.value}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <CustomSelect
            required
            label={t("sex")}
            menuItems={genderMenu}
            onChange={handleFieldChange}
            id={"sex"}
            name={"sex"}
            value={formFields.sex.value}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
            <InputLabel required sx={classes.nameField}>
              {t("birthDate")}
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={yearMenu}
            onChange={handleFieldChange}
            id={"year"}
            name={"year"}
            value={formFields.year.value}
            error={formFields.year.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={monthMenu}
            onChange={handleFieldChange}
            id={"month"}
            name={"month"}
            value={formFields.month.value}
            error={formFields.month.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={dateMenu}
            onChange={handleFieldChange}
            id={"date"}
            name={"date"}
            value={formFields.date.value}
            error={formFields.date.error}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
            <InputLabel required sx={classes.nameField}>
              {t("birthTime")}
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={hoursMenu}
            onChange={handleFieldChange}
            id={"hours"}
            name={"hours"}
            value={formFields.hours.value}
            error={formFields.hours.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={minutesMenu}
            onChange={handleFieldChange}
            id={"minutes"}
            name={"minutes"}
            value={formFields.minutes.value}
            error={formFields.minutes.error}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CustomSelect
            menuItems={secondsMenu}
            onChange={handleFieldChange}
            id={"seconds"}
            name={"seconds"}
            value={formFields.seconds.value}
            error={formFields.seconds.error}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
          <CustomInput
            required
            label={t("birthPlace")}
            placeHolder={t("place")}
            id="birthPlace"
            type="birthPlace"
            name="birthPlace"
            value={formFields.birthPlace.value}
            onChange={handleFieldChange}
            error={
              !isTruthy(
                formFields.birthPlace.value &&
                  address.latitude &&
                  address.longitude
              ) && formFields.birthPlace.error
            }
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
          <CustomSelect
            label={t("pregnancy")}
            menuItems={pregnancyMenu}
            onChange={handleFieldChange}
            id={"pregnancy"}
            name={"pregnancy"}
            value={formFields.pregnancy.value}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} mt={3}>
          <CustomButton
            label={t("save")}
            type={strings.PRIMARY}
            onClick={handleSaveProfile}
          />
        </Grid>
      </Grid>
    );
  };

  const getCalculator = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="lg" sx={{ px: 1 }}>
                {getForm()}
              </Container>
            </Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getCalculator();
};

export default Calculator;
