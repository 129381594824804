import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalizeWords } from "../../../helpers/methods";
import appHeaderStyles from "./AppHeader.styles";

const HoraLegends = () => {
  const classes = appHeaderStyles;
  const { t } = useTranslation();
  const planets = [
    "sun",
    "moon",
    "mars",
    "mercury",
    "jupiter",
    "venus",
    "saturn",
    "rahu",
    "ketu",
  ];

  const getHoraLegends = () => {
    return (
      <Stack direction={"column"} my={3}>
        {planets.map((planet: string, index: number) => {
          return (
            <>
              <Box sx={classes.notificationDateBox}>
                <Typography
                  sx={classes.notificationDateText}
                  px={{ sm: 5, xs: 2.5 }}
                >
                  Hora - {capitalizeWords(t(`${planet}`))}
                </Typography>
              </Box>
              <Stack direction={"column"} spacing={2} my={3}>
                <Stack direction={"column"} spacing={0.5}>
                  <Typography
                    sx={{
                      ...classes.notificationDateText,
                      textDecorationLine: "underline",
                    }}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    {t("characteristics")}:
                  </Typography>
                  <Typography
                    sx={{ ...classes.notificationDateText, fontSize: "15px" }}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    {t(`${planet}Characteristics`)}
                  </Typography>
                </Stack>
                <Stack direction={"column"} spacing={0.5}>
                  <Typography
                    sx={{
                      ...classes.notificationDateText,
                      textDecorationLine: "underline",
                    }}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    {t("recommendedActivities")}:
                  </Typography>
                  {[...Array(7)].map((_, index) => {
                    return (
                      <Typography
                        sx={{
                          ...classes.notificationDateText,
                          fontSize: "15px",
                        }}
                        px={{ sm: 5, xs: 2.5 }}
                      >
                        {index + 1}.{" "}
                        {t(`${planet}RecommendedActivities${index + 1}`)}
                      </Typography>
                    );
                  })}
                </Stack>
                <Stack direction={"column"} spacing={0.5}>
                  <Typography
                    sx={{
                      ...classes.notificationDateText,
                      textDecorationLine: "underline",
                    }}
                    px={{ sm: 5, xs: 2.5 }}
                  >
                    {t("avoidedActivities")}:
                  </Typography>
                  {[...Array(3)].map((_, index) => {
                    return (
                      <Typography
                        sx={{
                          ...classes.notificationDateText,
                          fontSize: "15px",
                        }}
                        px={{ sm: 5, xs: 2.5 }}
                      >
                        {index + 1}.{" "}
                        {t(`${planet}AvoidedActivities${index + 1}`)}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            </>
          );
        })}
      </Stack>
    );
  };

  return getHoraLegends();
};

export default HoraLegends;
