import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../../helpers/methods";
import { getAnalysisData } from "../../AnalysisService";
import AnalysisFlipCards from "./AnalysisFlipCards";

interface CustomProps {
  profileId?: string;
  setIsLoading: Function;
}

const AnalysisCards = (props: CustomProps) => {
  const [analysisData, setAnalysisData] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    getAnalysis();
  }, [i18n.language]);

  const getAnalysis = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getAnalysisData(props?.profileId!, language);
      setAnalysisData(response.analysisDetails);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  return (
    <Grid container py={1}>
      {analysisData?.map((data: any) => {
        return <AnalysisFlipCards flipCardData={data} />;
      })}
    </Grid>
  );
};

export default AnalysisCards;
