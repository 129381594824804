import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  theme,
} from "../../utils/styles";

const calculatorStyle = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 195px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    margin: "25px 0px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 252px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 235px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    ...centerItemFlex,
    // display: "grid",
    // alignContent: "center",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    [theme.breakpoints.down("sm")]: {
      px: 1,
    },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  // mainBox: {
  //   p: 1,
  //   borderRadius: "20px",
  //   height: "calc(100vh - 220px)",
  //   background: "rgb(103 103 103 / 15%)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(7px)",
  //   border: "1px solid rgb(255 255 255 / 17%)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 222px)",
  //   },
  // },
  // subBox: {
  //   p: 3,
  //   height: "calc(100vh - 270px)",
  //   borderRadius: "20px",
  //   border: "1px solid rgb(111 111 111 / 22%)",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   "&::-webkit-scrollbar": {
  //     width: "5px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     borderRadius: "10px",
  //     border: "1px solid #FFFFFF",
  //     background: "#282945",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     p: 1,
  //     height: "calc(100vh - 240px)",
  //   },
  // },
  mainGrid: {
    // height: "calc(100vh - 215px)",
  },
  headerText: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(6),
    pl: 1,
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: getRelativeFontSize(40),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(20),
    },
  },
  formStyle: {
    px: 2,
    height: "calc(100vh - 255px)",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 205px)",
    },
  },
  subHeaderText: {
    fontSize: getRelativeFontSize(6),
    color: "#000000",
  },
  textField: {
    width: "100%",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(5.5px)",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "1px solid #FFFFFF",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "10px",

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  nameField: {
    ...mediumFont,
    color: "#FFFFFF",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  nameTextField: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  btnStyle: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  btnBox: {
    background: "#060b25",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  icon: {
    fill: "#FFFFFF",
  },
  searchPlaceInputWrapper: {
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#060b25,#060b25),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",
    zIndex: 0,
  },
  searchPlaceInput: {
    ...mediumFont,
    padding: "15px 10px",
    width: "-webkit-fill-available",
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    outline: "none",
    color: "#FFFFFF",
    zIndex: 1,
  },
  errorStyling: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
} as const;

export default calculatorStyle;
