import Box from "@mui/system/Box";
import { FC, Suspense } from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import backgroundImage from "./assets/images/backgroundImage.png";
import withClearCache from "./ClearCache";
import urls from "./global/constants/UrlConstants";
import "./i18n";
import Home from "./screens/Home/Home";
import LandingPage from "./screens/LandingPage/LandingPage";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Fail from "./screens/Pricing/Components/Fail";
import Success from "./screens/Pricing/Components/Success";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import Layout from "./screens/Shared/Layout/Layout";
import history from "./utils/history";
import Notifications from "./utils/notifications";
import ScrollToTopRoute from "./utils/ScrollToTop";

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp: FC = () => {
  return (
    <Box
      sx={{
        background: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "auto",
        // height: "calc(100vh - 150px)",
      }}
    >
      <Suspense fallback={null}>
        <BrowserRouter
          basename="/index.html"
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Router history={history}>
            <ScrollToTopRoute>
              <Switch>
                <Route exact path={urls.homeViewPath} component={Home} />
                <Route exact path={urls.successViewPath} component={Success} />
                <Route exact path={urls.failViewPath} component={Fail} />
                <Route
                  exact
                  path={urls.privacyPolicyViewPath}
                  component={PrivacyPolicy}
                />
                <Route exact path={urls.successViewPath} component={Success} />
                <Route exact path={urls.failViewPath} component={Fail} />
                <Route
                  exact
                  path={[
                    urls.loginViewPath,
                    urls.registerViewPath,
                    urls.setPasswordViewPath,
                    urls.resetPasswordViewPath,
                    urls.deleteAccountViewPath,
                  ]}
                  component={LandingPage}
                />
                <Layout />
                <Route path={""} component={PageNotFound} />
              </Switch>
            </ScrollToTopRoute>
            <Notifications />
          </Router>
        </BrowserRouter>
      </Suspense>
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
