import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import rightIcon from "../../../../assets/icons/Group (1).svg";
import leftIcon from "../../../../assets/icons/Group.svg";
import noImage from "../../../../assets/images/noImage1.png";
import { handleErrorMessage } from "../../../../helpers/methods";
import { theme } from "../../../../utils/styles";
import Firework from "./Firework";
import FootballStyle from "./Football.styles";
import FootballGraph from "./FootballGraph";
import FootballGround from "./FootballGround";
import { footballPrediction } from "./FootballService";

interface CustomProps {
  profileId: string;
  setIsLoading: Function;
  setDisplayVideo: Function;
  setGoldenFoot: Function;
}

const Football = (props: CustomProps) => {
  const classes = FootballStyle;
  const { t } = useTranslation();
  const [display, setDisplay] = useState<boolean>(false);
  const [goldenFoot, setGoldenFoot] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<any>();
  const [positionPercentage, setPositionPercentage] = useState<any>({});
  const [category, setCategory] = useState<any>({
    firstChoiceName: "",
    firstChoiceColor: "",
    secondChoiceName: "",
    secondChoiceColor: "",
    side: "",
    footballerChance: "",
  });
  const emptyGraphData: any = {
    Defender: "32.15",
    Forward: "14.02",
    Goalkeeper: "10.00",
    Midfielder: "26.13",
    Striker: "17.70",
  };

  useEffect(() => {
    if (display) {
      const timer = setTimeout(() => {
        setDisplay(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [display]);

  useEffect(() => {
    handlePredict();
  }, []);

  const handlePredict = async () => {
    try {
      props.setIsLoading(true);
      const response = await footballPrediction(props?.profileId!);
      setPositionPercentage(response.positionPercentage);
      setGraphData(response.percentage);
      setCategory({
        firstChoiceName: response.firstChoiceName,
        firstChoiceColor: response.firstChoiceColor,
        secondChoiceName: response.secondChoiceName,
        secondChoiceColor: response.secondChoiceColor,
        side: response.side,
        footballerChance: response.footballerChance,
      });
      setGoldenFoot(response.goldenFoot);
      props.setGoldenFoot(response.goldenFoot);
      props.setDisplayVideo(true);
      setTimeout(() => {
        if (response.goldenFoot) {
          setDisplay(true);
        }
        props.setDisplayVideo(false);
      }, 10000);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getFootballGraphComponent = () => {
    return (
      <Box sx={classes.footballFormWrapper}>
        {graphData ? (
          <FootballGraph
            percentages={graphData}
            needColor={true}
            footballerChance={category.footballerChance}
          />
        ) : (
          <FootballGraph percentages={emptyGraphData} needColor={false} />
        )}
      </Box>
    );
  };

  const getFootballGroundComponent = () => {
    return graphData && positionPercentage ? (
      <Box sx={{ height: "100%" }}>
        <FootballGround
          category={category}
          goldenFoot={goldenFoot}
          percentages={positionPercentage}
        />
      </Box>
    ) : (
      <Box
        component="img"
        src={noImage}
        alt={"Image not found"}
        sx={{
          borderRadius: "20px",
          width: "100%",
          height: "100%",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      />
    );
  };

  const getContent = () => {
    return (
      <Box py={1}>
        {category.firstChoiceName && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <Box
              component="img"
              src={leftIcon}
              alt={"decoration"}
              sx={{
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  width: "18vw",
                },
              }}
            />
            <Typography
              sx={{
                ...classes.gradientText,
                margin: "0px",
                textWrap: "nowrap",
              }}
              px={2}
            >
              {t(`${category.side}`)} {t(`${category.firstChoiceName}`)}
            </Typography>
            <Box
              component="img"
              src={rightIcon}
              alt={"decoration"}
              sx={{
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  width: "18vw",
                },
              }}
            />
          </Box>
        )}
        <Grid container spacing={4}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            {getFootballGraphComponent()}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            {getFootballGroundComponent()}
          </Grid>
        </Grid>
        {goldenFoot && (
          <Typography sx={classes.gradientText} pt={2}>
            {t("goldenFootLabel")}
          </Typography>
        )}
      </Box>
    );
  };

  const getFootball = () => {
    return (
      <>
        {display && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 200,
            }}
          >
            <Firework />
          </Box>
        )}
        {getContent()}
      </>
    );
  };

  return getFootball();
};

export default Football;
