import { useState } from "react";
import { Box } from "@mui/material";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import pricingStyles from "./Pricing.styles";
import strings from "../../global/constants/StringConstants";
import Plans from "./Components/Plans";
import Payment from "./Components/Payment";

interface CustomProps {
  location?: any;
}

const Pricing = (props: CustomProps) => {
  const classes = pricingStyles;
  const urlParams = new URLSearchParams(window.location.search);
  const change = urlParams.get("change");
  const changePlan = change === "true" ? true : false;
  const redirectionState = props.location?.state;
  const [isLoading, setIsLoading] = useState(false);

  const getComponentBasedOnUrl = () => {
    const page = window.location?.pathname?.split("/")[1].toLowerCase();
    switch (page) {
      case strings.PRICING: {
        return <Plans setIsLoading={setIsLoading} change={changePlan} />;
      }
      case strings.PAYMENT: {
        return (
          <Payment
            selectedPlan={redirectionState?.selectedPlan}
            change={Boolean(redirectionState?.change)}
            setIsLoading={setIsLoading}
          />
        );
      }
      default: {
        return <Plans setIsLoading={setIsLoading} change={changePlan} />;
      }
    }
  };

  const getContent = () => {
    return (
      <Box sx={classes.mainContainer}>
        <Box sx={classes.mainBox}>
          <Box sx={classes.subBox}>{getComponentBasedOnUrl()}</Box>
        </Box>
      </Box>
    );
  };

  const getPricing = () => {
    return (
      <Box>
        {getContent()}
        <CustomLoader isLoading={isLoading} />
      </Box>
    );
  };

  return getPricing();
};

export default Pricing;
