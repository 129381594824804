import {
  Box,
  Container,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import backArrow from "../../assets/icons/backArrow.svg";
import contactIcon from "../../assets/icons/contactIcon.svg";
import { CustomButton, CustomInput } from "../../global/components";
import { CustomContactNumberInput } from "../../global/components/CustomContactNumberInput/CustomContactNumberInput";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import strings from "../../global/constants/StringConstants";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import { profileUpdateAction } from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppDispatch } from "../../utils/hooks";
import { theme } from "../../utils/styles";
import {
  registerFormField,
  registrationValidation,
} from "../Auth/AuthTypesAndValidation";
import ContactUsDialog from "../Shared/Dialogs/ContactUsDialog/ContactUsDialog";
import userProfileStyles from "./UserProfile.styles";
import { getUserProfileDetails, updateUserProfile } from "./UserProfileService";

const UserProfile = () => {
  const classes = userProfileStyles;
  const emailRegex = strings.regex;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const [formFields, setFormFields] = useState(registerFormField);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openContactUs, setOpenContactUs] = useState<boolean>(false);
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });

  useEffect(() => {
    getMyPlan();
    initialize();
  }, []);

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        location: {
          ...formFields.location,
          value: address.location,
        },
      });
    !formFields.location.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  const getMyPlan = async () => {
    try {
      setIsLoading(true);
      const response = await getUserProfileDetails();
      setFormFields({
        firstName: { value: response.firstName, error: "" },
        lastName: { value: response.lastName, error: "" },
        email: { value: response.email, error: "" },
        location: { value: response.location, error: "" },
        contact: { value: response.contactNumber, error: "" },
      });
      setAddress({
        latitude: response.latitude,
        longitude: response.longitude,
        location: response.location,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      if (handleValidation()) {
        const profileData = {
          firstName: formFields.firstName.value.trim(),
          lastName: formFields.lastName.value.trim(),
          location: address.location,
          latitude: address.latitude,
          longitude: address.longitude,
          contactNumber: formFields.contact.value,
        };
        setIsLoading(true);
        const response = await updateUserProfile(profileData);
        openSuccessNotification(response.message);
        dispatch(
          profileUpdateAction({
            firstName: formFields.firstName.value.trim(),
            lastName: formFields.lastName.value.trim(),
          })
        );
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = registrationValidation(formFields, address);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
    if (event.target.name === "location") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  function initialize() {
    const input: any = document.getElementById("location");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  }

  const handleClose = () => {
    setOpenContactUs(false);
  };

  const getTextFields = () => {
    return (
      <Grid container pb={4}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pb={2}>
          <CustomInput
            required
            label={t("firstName")}
            placeHolder={t("firstNamePlaceholder")}
            id="firstName"
            type="firstName"
            name="firstName"
            value={formFields.firstName.value}
            onChange={handleOnChangeInputField}
            error={
              !isTruthy(formFields.firstName.value) &&
              formFields.firstName.error
            }
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pb={2}>
          <CustomInput
            required
            label={t("lastName")}
            placeHolder={t("lastNamePlaceholder")}
            id="lastName"
            type="lastName"
            name="lastName"
            value={formFields.lastName.value}
            onChange={handleOnChangeInputField}
            error={
              !isTruthy(formFields.lastName.value) && formFields.lastName.error
            }
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pb={2}>
          <CustomInput
            label={t("email")}
            placeHolder={t("emailPlace")}
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            disabled
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pb={2}>
          <CustomInput
            required
            label={t("location")}
            placeHolder={t("locationPlace")}
            id="location"
            type="location"
            name="location"
            value={formFields.location.value}
            onChange={handleOnChangeInputField}
            error={
              !isTruthy(
                formFields.location.value &&
                  address.latitude &&
                  address.longitude
              ) && formFields.location.error
            }
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pb={2}>
          <CustomContactNumberInput
            label={t("contact")}
            id="contact_no_modal_field"
            value={formFields.contact.value}
            placeHolder="Enter Your Contact No"
            onChange={(phone: any) => {
              setFormFields({
                ...formFields,
                contact: {
                  value: phone,
                  error: "",
                },
              });
            }}
            error={formFields.contact.error}
          />
        </Grid>
      </Grid>
    );
  };

  const getHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { sm: "center", xs: "baseline" },
          width: "100%",
        }}
        pb={3}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => history.goBack()}
        >
          {isMobile ? (
            <img src={backArrow} height={"20px"} alt="Module not found..." />
          ) : (
            <img src={backArrow} height={"25px"} alt="Module not found..." />
          )}

          {/* <Typography pl={2} sx={classes.backText}>
            Back
          </Typography> */}
        </Box>
        <Typography sx={classes.italicTypo}>{t("myProfile")}</Typography>
      </Box>
    );
  };

  const getUpdateButton = () => {
    return (
      <CustomButton
        label={t("update")}
        type={strings.PRIMARY}
        onClick={handleUpdateProfile}
      />
    );
  };

  const getContactUs = () => {
    return (
      <Box
        display={"flex"}
        pt={4}
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenContactUs(true)}
      >
        <img
          src={contactIcon}
          alt="Module not found..."
          style={{ width: "25px" }}
        />
        <Typography pl={2} sx={classes.gradientTypo}>
          {t("contactUs")}
        </Typography>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <Container maxWidth="sm">
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {getHeader()}
          {getTextFields()}
          {getUpdateButton()}
          {getContactUs()}
          <ContactUsDialog
            setIsLoading={setIsLoading}
            setOpenContactUs={setOpenContactUs}
            handleClose={handleClose}
            openContactUs={openContactUs}
          />
        </Stack>
      </Container>
    );
  };

  const getUserProfile = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>{getContent()}</Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };
  return getUserProfile();
};

export default UserProfile;
