import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const profileStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 195px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    margin: "25px 0px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 252px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 235px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    ...centerItemFlex,
    // display: "grid",
    // alignContent: "center",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    [theme.breakpoints.down("sm")]: {
      px: 1,
    },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  // mainBox: {
  //   p: 1,
  //   borderRadius: "20px",
  //   height: "calc(100vh - 220px)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   background: "rgba(255, 255, 255, 0.07)",
  //   backdropFilter: "blur(12px)",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 222px)",
  //   },
  // },
  // subBox: {
  //   p: 3,
  //   height: "calc(100vh - 270px)",
  //   borderRadius: "20px",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   "&::-webkit-scrollbar": {
  //     width: "5px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     borderRadius: "10px",
  //     border: "1px solid #FFFFFF",
  //     background: "#282945",
  //   },
  //   border: "1px solid rgb(111 111 111 / 22%)",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 240px)",
  //     p: 1,
  //   },
  // },
  headerText: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(10),
    pl: 2,
    alignSelf: "center",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  cardStyle: {
    // backgroundClip: "content-box,border-box",
    // backgroundImage:
    //   "linear-gradient(#060b25,#060b25),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    // backgroundOrigin: "border-box",
    border: "1px solid #ffffff",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(5.5px)",
    padding: "10px 0px",
    alignItems: "center",
    // height: "100%",
    // minHeight: "45px",

    cursor: "pointer",
    transformStyle: "preserve-3d",
    transformOrigin: "center right",
    transition: "transform 1s",
    "&.is-flipped": {
      // transform: "translateX(-100%) rotateY(-180deg)",
      WebkitTransform: "rotateY(-180deg) translate(100%, 0)",
      transform: "rotateY(-180deg) translate(100%, 0)",
    },
  },
  textField: {
    width: "100%",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(5.5px)",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "1px solid #FFFFFF",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "10px",

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  contactUsField: {
    width: "99%",
    background: "transparent",
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#ffffff,#ffffff),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#000000",

      "&::placeholder": {
        ...mediumFont,
        color: "##000000de",
      },
    },
    "& .MuiSelect-icon": {
      color: "#000000",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#ffffff",
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
  nameField: {
    ...mediumFont,
    color: "#FFFFFF",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  icon: {
    fill: "#FFFFFF",
  },
  cardLabel: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  cardText: {
    ...regularFont,
    color: "#ffffffe8",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  btnStyle: {
    // backgroundImage:
    //   "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    // borderRadius: "10px",
    // boxSizing: "border-box",
    background: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(5.5px)",
    borderRadius: "10px",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  btnBox: {
    background: "#060b25",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  loginBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#9053F6",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    "&:disabled": {
      background: "#c09aff",
    },
  },
  loginBtnTypo: {
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
  },
  contactUsHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(16),
    color: "#222222",
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  errorStyling: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: getRelativeFontSize(40),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(20),
    },
  },
  modalHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(8),
    color: "#222222",
    textAlign: "center",
  },
  lightFont: {
    ...regularFont,
    color: "#666666",
    textAlign: "center",
    fontSize: getRelativeFontSize(5),
  },
  cancelBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(3),
    },
  },
  cancelBtnBox: {
    background: "#FFFFFF",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  logoutBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color: "#FFFFFF",
      background: "#FFFFFF",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(5),
    },
  },
} as const;

export default profileStyles;
