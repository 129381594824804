import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomIcon } from "../../../../global/components";
import { handleErrorMessage } from "../../../../helpers/methods";
import { boldFont, theme } from "../../../../utils/styles";
import analysisStyles from "../../Analysis.styles";
import { getInnerDashaData, getMahadashaData } from "../../AnalysisService";
import MahadashaChartComponent from "./MahadashaChartComponent";

interface CustomProps {
  profileId: string;
  setIsLoading: Function;
}

const Mahadasha = (props: CustomProps) => {
  const classes = analysisStyles;
  const { i18n } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [currentData, setCurrentData] = useState([]);
  const [colorPercentage, setColorPercentage] = useState<any[]>([]);
  const [dashaDetails, setDashaDetails] = useState({
    dashaName: "",
    dashaTagline: "",
    endDate: "",
    pranadashaPlanet: "",
    startDate: "",
  });
  const [dashaData, setDashaData] = useState<any[]>([]);
  const [colorTagline, setColorTagline] = useState<any[]>([]);

  const planetColorMapping: any = {
    Ketu: "white",
    Venus: "#22cb22",
    Sun: "#22cb22",
    Moon: "#22cb22",
    Mars: "red",
    Rahu: "white",
    Jupiter: "#22cb22",
    Saturn: "red",
    Mercury: "#22cb22",
  };

  const colorNameMapping: any = {
    red: "Malefic",
    green: "Benefic",
    white: "Neutral",
  };

  useEffect(() => {
    getMahadashaDetails();
  }, [i18n.language]);

  const getMahadashaDetails = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getMahadashaData(props?.profileId!, language);
      setCurrentData(response.dasha);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const setInnerDashaDetails = (data: any) => {
    setCurrentData(data.dasha);
    setDashaDetails({
      dashaName: data.dashaName,
      dashaTagline: data.dashaTagline,
      endDate: data.enddate ?? "",
      pranadashaPlanet: data.pranadashaPlanet ?? "",
      startDate: data.startdate ?? "",
    });
    setColorTagline(data.colorTagline);
    const inputArray = Object.entries(data.percentage);
    const totalPercentage = inputArray.reduce(
      (acc, [, percentage]: any) => acc + percentage,
      0
    );
    const outputArray: any[] = inputArray.map(([color, percentage]: any) => ({
      color: colorNameMapping[color],
      percentage: (percentage / totalPercentage) * 100,
    }));
    setColorPercentage(outputArray);
  };

  const getInnerDasha = async (
    endDate: string,
    planetName: string,
    startDate: string
  ) => {
    try {
      const obj = {
        endDate,
        planetName,
        startDate,
      };
      setDashaData([...dashaData, obj]);
      props.setIsLoading(true);
      const response = await getInnerDashaData(obj);
      setInnerDashaDetails(response);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleBack = (dashaName: string) => {
    if (dashaName === "Antar Dasha") {
      getMahadashaDetails();
      setDashaData([]);
      setDashaDetails({
        dashaName: "",
        dashaTagline: "",
        endDate: "",
        pranadashaPlanet: "",
        startDate: "",
      });
    } else {
      dashaData.pop();
      const obj = dashaData.pop();
      getInnerDasha(obj.endDate, obj.planetName, obj.startDate);
    }
  };

  const getMahadasha = () => {
    return (
      <Grid container py={2} sx={{ rowGap: "15px" }}>
        {dashaDetails.dashaName && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.mahadashaHeader}
          >
            <Box
              sx={{
                paddingBottom: "45px",
                cursor: "pointer",
              }}
              onClick={() => handleBack(dashaDetails.dashaName)}
            >
              <KeyboardBackspaceIcon
                htmlColor="#FFFFFF"
                fontSize={isTablet ? "medium" : "large"}
              />
            </Box>
            <Stack direction={"column"} pl={{ md: 2, xs: 0.5 }}>
              <Typography sx={classes.dashaNameTypo} pb={1.5}>
                {dashaDetails.dashaName}
              </Typography>
              <Grid
                container
                sx={{
                  borderTop: "2px solid #ffffff73",
                  pt: 1.5,
                }}
              >
                {colorTagline.map((tagline: any, index: number) => {
                  return (
                    <Grid item display={"flex"} alignItems={"center"}>
                      <Typography
                        pl={0.5}
                        sx={{
                          ...classes.dashaTagline,
                          color: planetColorMapping[tagline.planetName ?? ""],
                        }}
                      >
                        {tagline.planetName}
                      </Typography>
                      <Typography
                        px={0.5}
                        sx={{
                          ...classes.dateTypo,
                          width: "fit-content",
                        }}
                      >
                        ({tagline.dasha})
                      </Typography>
                      {index < colorTagline.length - 1 && (
                        <Typography
                          px={0.5}
                          sx={{
                            ...classes.dateTypo,
                            width: "fit-content",
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
            {!isTablet && (
              <Box>
                <MahadashaChartComponent data={colorPercentage} />
              </Box>
            )}
          </Grid>
        )}

        {dashaDetails.dashaName && isTablet && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={classes.mahadashaHeader}
            justifyContent={"center"}
          >
            <Box>
              <MahadashaChartComponent data={colorPercentage} />
            </Box>
          </Grid>
        )}

        {dashaDetails.pranadashaPlanet && (
          <>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ ...classes.mahadashaContent, cursor: "auto" }}
            >
              <Stack direction={"row"} spacing={1}>
                <Typography sx={classes.dateTypo}>
                  {dashaDetails.startDate}
                </Typography>
                <CustomIcon icon={<HorizontalRuleIcon htmlColor="#9F3AFF" />} />
                <Typography sx={classes.dateTypo}>
                  {dashaDetails.endDate}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ ...classes.mahadashaContent, cursor: "auto" }}
            >
              <Stack direction={"column"} pl={2}>
                <Typography
                  sx={{
                    ...classes.planetNameTypo,
                    color:
                      planetColorMapping[dashaDetails.pranadashaPlanet ?? ""],
                    ...boldFont,
                  }}
                  pb={1.5}
                >
                  {dashaDetails.pranadashaPlanet}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={1}
                  pt={1.5}
                  sx={{
                    width: "fit-content",
                    borderTop: "2px solid #ffffff73",
                  }}
                >
                  <Typography sx={classes.dateTypo}>
                    {dashaDetails.startDate}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </>
        )}

        {currentData?.map((data: any) => {
          return (
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={
                dashaData.length < 4
                  ? classes.mahadashaContent
                  : { ...classes.mahadashaContent, cursor: "auto" }
              }
              onClick={() => {
                dashaData.length < 4 &&
                  getInnerDasha(data.endDate, data.planetName, data.startDate);
              }}
            >
              <Stack direction={"column"} pl={2}>
                <Typography
                  sx={{
                    ...classes.planetNameTypo,
                    ...(dashaData.length === 4 && boldFont),
                  }}
                  pb={1.5}
                >
                  {data.planetName
                    .split(/[\s-]+/)
                    .map((part: string, index: number, arr: any) => (
                      <React.Fragment key={index}>
                        <span
                          style={{
                            color: planetColorMapping[part.trim()]
                              ? planetColorMapping[part.trim()]
                              : "white",
                          }}
                        >
                          {part}
                        </span>
                        {index < arr.length - 1 && (
                          <span style={{ color: "white" }}> - </span>
                        )}
                      </React.Fragment>
                    ))}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={1}
                  pt={1.5}
                  sx={{
                    width: "fit-content",
                    borderTop: "2px solid #ffffff73",
                  }}
                >
                  <Typography sx={classes.dateTypo}>
                    {data.startDate}
                  </Typography>
                  <CustomIcon
                    icon={<HorizontalRuleIcon htmlColor="#9F3AFF" />}
                  />
                  <Typography sx={classes.dateTypo}>{data.endDate}</Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return getMahadasha();
};

export default Mahadasha;
